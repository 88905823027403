import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { TradingAccountService } from '../services/trading-account.service';
import * as signalR from '@aspnet/signalr';
import { environment } from '../../environments/environment';
import { UserService } from '../services/user.service';
import { RoAccountService } from '../services/ro-account.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Language, LocaleService, TranslationService } from 'angular-l10n';

@Component({
  selector: 'app-ro-account',
    templateUrl: './ro-account.component.html',
    styleUrls: ['./ro-account.component.scss'],
 
})
export class RoAccountComponent implements OnInit {
    roAccount: any;
    roTransactions: any[] = [];
    frmRoTransfer: FormGroup;
    securityTokenSent = false;
    firstStepMsg = '';
    destinaionAccountHolder = '';
    @Language() lang: string;


    constructor(private toastr: ToastrService, private router: Router, private modalService: NgbModal,
        public locale: LocaleService, public translation: TranslationService,
        private roAccountService: RoAccountService, public userService: UserService, private fb: FormBuilder) {
}

    ngOnInit() {
        //this.userService.getUserStatus();
        const connectionApiAdmin = new signalR.HubConnectionBuilder()
            .configureLogging(signalR.LogLevel.Information)
            .withUrl(environment.adminBaseUrl + "/notifications")
            .build();
        connectionApiAdmin.start().then(function () {
            console.log('Connected hub admin');
        }).catch(function (err) {
            return console.error(err.toString());
        });

        connectionApiAdmin.on("prvcmd", (msg) => {
            const jsonMsg = JSON.parse(msg);

            switch (jsonMsg.command) {

                case "reloadRoAccount":
                    if (this.userService.userStatus && this.userService.userStatus.id === jsonMsg.uId) {

                        setTimeout(() => {
                            this.getRoAccount();
                        }, 1000);
                    }
                    break;
                case "reloadTransactionsOfRoAccount":
                    if (this.userService.userStatus && this.userService.userStatus.id === jsonMsg.uId) {

                        setTimeout(() => {
                          ///  this.getRoTransactions();
                            this.getRoAccount();
                            
                        }, 1000);


                    }

                default:
                    break;
            }
        });

        const connectionApi = new signalR.HubConnectionBuilder()
            .configureLogging(signalR.LogLevel.Information)
            .withUrl(environment.apiBaseUrl + "/notifications")
            .build();
        connectionApi.start().then(function () {
            console.log('Connected hub api');
        }).catch(function (err) {
            return console.error(err.toString());
        });

        connectionApi.on("prvcmd", (msg) => {
            const jsonMsg = JSON.parse(msg);

            switch (jsonMsg.command) {

                case "reloadRoAccount":
                    if (this.userService.userStatus && this.userService.userStatus.id === jsonMsg.uId) {

                        setTimeout(() => {
                            this.getRoAccount();
                        }, 1000);
                    }
                    break;
                case "reloadTransactionsOfRoAccount":
                    if (this.userService.userStatus && this.userService.userStatus.id === jsonMsg.uId) {

                        setTimeout(() => {
                            ///  this.getRoTransactions();
                            this.getRoAccount();

                        }, 1000);


                    }

                default:
                    break;
            }
        });
        this.getRoAccount();

    }

    getRoAccount() {
        this.roAccountService.getUserRoAccount()
            .subscribe(res => {
             //   console.log('getRoAccount', res);
                this.roAccount = res;
                this.getRoTransactions();
            }, error => {
                    this.roAccount = null;
                    console.log(error);
            });
    }
    getRoTransactions() {
        this.roAccountService.getRoTransactions(this.roAccount.id)
            .subscribe(res => {
                console.log('getRoTransactions', res);
                this.roTransactions = res;
            }, error => {
                this.roTransactions = [];
                console.log(error);
            });
    }

    displayCardNumber(str) {
        let ret='';
        let remaining = str;

        while (remaining) {
            let v = remaining.slice(0, 4);
            remaining = remaining.slice(v.length);
            ret += ' ' + v;
        }

        return ret;
    }
 
    openModal(content) {
        this.securityTokenSent = false;
        this.firstStepMsg = '';
        this.frmRoTransfer = this.fb.group({
            trasnferType: ['1', Validators.required],
            cardNumber: [''],
            accountHolder: [''],
            bankAccountHolderName: [''],
            iban: [''],
            bankName: [''],
            country: [''],
            amount: ['', Validators.required],
            description: [''],
            securityToken: [''],
            usdtTrc20WalletNumber: [''],
        });
        this.modalService.open(content);  
    }
 
    roTransferNextClick() {
        if (this.frmRoTransfer.invalid) {
            this.toastr.error('Please complete the form');
            return;
        }
        let model = {
            trasnferType: this.frmRoTransfer.value.trasnferType,
            cardNumber: this.frmRoTransfer.value.cardNumber,
            bankAccountHolderName: this.frmRoTransfer.value.bankAccountHolderName,
            iban: this.frmRoTransfer.value.iban,
            bankName: this.frmRoTransfer.value.bankName,
            country: this.frmRoTransfer.value.country,

            amount: this.frmRoTransfer.value.amount,
            description: this.frmRoTransfer.value.description,
            usdtTrc20WalletNumber: this.frmRoTransfer.value.usdtTrc20WalletNumber
        };
        
        this.roAccountService.submitRoTransferFirstStep(model).subscribe(res => {
            this.firstStepMsg = res.message;
            this.destinaionAccountHolder = res.roAccountHolder;
            this.securityTokenSent = true;
            this.toastr.success(res.message);
        }, error => {
            // console.log(error);
            if (error.error.errors) {
                error.error.errors.forEach(d => {
                    this.toastr.error(d);
                })
            } else {
                this.toastr.error(error.error.error);
            }
        });
        return;
    }

    onSubmitRoTransfer() {
        if (this.frmRoTransfer.invalid) {
            this.toastr.error('Please complete the form');
            return;
        }
        if (!this.frmRoTransfer.value.securityToken) {
            this.toastr.error('Security token is required.');
            return;
        }
        
        let model = {
            trasnferType: this.frmRoTransfer.value.trasnferType,
            cardNumber: this.frmRoTransfer.value.cardNumber,
            bankAccountHolderName: this.frmRoTransfer.value.bankAccountHolderName,
            iban: this.frmRoTransfer.value.iban,
            bankName: this.frmRoTransfer.value.bankName,
            country: this.frmRoTransfer.value.country,
            amount: this.frmRoTransfer.value.amount,
            description: this.frmRoTransfer.value.description,
            securityToken: this.frmRoTransfer.value.securityToken,
            usdtTrc20WalletNumber: this.frmRoTransfer.value.usdtTrc20WalletNumber
        };

        this.roAccountService.addTransfer(model).subscribe(res => {
            this.modalService.dismissAll();
            this.toastr.success(res.message);
            this.securityTokenSent = false;
            this.firstStepMsg = '';
        }, error => {
            // console.log(error);
            if (error.error.errors) {
                error.error.errors.forEach(d => {
                    this.toastr.error(d);
                })
            } else {
                this.toastr.error(error.error.error);
            }
        });
    }
    changeTransferType(id: string) {
        this.frmRoTransfer.patchValue({
            trasnferType: id
        });
        
    }

}
