import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-product-commodity',
    templateUrl: './product-commodity.component.html',
    styleUrls: ['./product-commodity.component.scss']
})
export class ProductCommodityComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
