import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { L10nConfig, L10nLoader, TranslationModule, StorageStrategy, ProviderType, LocaleService } from 'angular-l10n';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DragulaModule } from 'ng2-dragula';
import { ClipboardModule } from 'ngx-clipboard';
import { ContextMenuModule } from 'ngx-contextmenu';
import { NouisliderModule } from 'ng2-nouislider';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { DROPZONE_CONFIG } from 'ngx-dropzone-wrapper';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { ColorPickerModule } from 'ngx-color-picker';
import { AngularDateTimePickerModule } from 'angular2-datetimepicker';
import { AmazingTimePickerModule } from 'amazing-time-picker';
import { TagInputModule } from 'ngx-chips';
import { FormWizardModule } from 'angular2-wizard';
import { NgxSummernoteModule } from 'ngx-summernote';
import { TinymceModule } from 'angular2-tinymce';
import { AceEditorModule } from 'ng2-ace-editor';
import { CodemirrorModule } from 'ng2-codemirror';
import { ChartsModule } from 'ng2-charts';
import { Select2Module } from 'ng2-select2';
import { MorrisJsModule } from 'angular-morris-js';
import { ChartistModule } from 'ng-chartist';
import { DataTablesModule } from 'angular-datatables';
import { SweetAlert2Module } from '@toverux/ngx-sweetalert2';
import { ToastrModule } from 'ngx-toastr';
import { AgmCoreModule } from '@agm/core';
import { BarRatingModule } from "ngx-bar-rating";
import { FullCalendarModule } from 'ng-fullcalendar';
import { CookieService } from 'ngx-cookie-service';

import { VgCoreModule } from 'videogular2/compiled/core';
import { VgControlsModule } from 'videogular2/compiled/controls';
import { VgOverlayPlayModule } from 'videogular2/compiled/overlay-play';
import { VgBufferingModule } from 'videogular2/compiled/buffering';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { FooterComponent } from './shared/footer/footer.component';
import { WebtradeIndexComponent } from './webtrade/webtrade-index/webtrade-index.component';
import { Page404Component } from './error-pages/page404/page404.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CustomFormsModule } from 'ng2-validation'
import { Page500Component } from './error-pages/page500/page500.component';
import { LockscreenComponent } from './general-pages/lock-screen/lock-screen.component';

import { FaqComponent } from './general-pages/faq/faq.component';
import { Faq2Component } from './general-pages/faq2/faq2.component';
import { NewsGridComponent } from './general-pages/news-grid/news-grid.component';
import { TimelineComponent } from './general-pages/timeline/timeline.component';
import { SearchResultsComponent } from './general-pages/search-results/search-results.component';
import { InvoiceComponent } from './general-pages/invoice/invoice.component';
import { OrdersComponent } from './general-pages/orders/orders.component';
import { SettingsPanelComponent } from './shared/settings-panel/settings-panel.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';

import { ProductsIndexComponent } from './general-pages/products-index/products-index.component';
import { ProductForexComponent } from './general-pages/product-forex/product-forex.component';
import { ProductStockComponent } from './general-pages/product-stock/product-stock.component';
import { ProductCryptoComponent } from './general-pages/product-crypto/product-crypto.component';
import { ProductIndicesComponent } from './general-pages/product-indices/product-indices.component';
import { ProductCommodityComponent } from './general-pages/product-commodity/product-commodity.component';
import { CoursesIndexComponent } from './general-pages/courses-index/courses-index.component';
import { CourseBondsComponent } from './general-pages/course-bonds/course-bonds.component';
import { CourseForexComponent } from './general-pages/course-forex/course-forex.component';
import { CourseForexOptionsComponent } from './general-pages/course-forex-options/course-forex-options.component';
import { CourseEquitiesComponent } from './general-pages/course-equities/course-equities.component';
import { CourseCfdsComponent } from './general-pages/course-cfds/course-cfds.component';
import { CourseOptionsComponent } from './general-pages/course-options/course-options.component';
import { CourseFuturesComponent } from './general-pages/course-futures/course-futures.component';
import { PlatformsComponent } from './general-pages/platforms/platforms.component';

import { CourseDetailsComponent } from './general-pages/course-details/course-details.component';
import { AccountWizardComponent } from './account-wizard/account-wizard.component';

import { environment } from '../environments/environment';
import { OidcConfigService, AuthModule, OidcSecurityService, ConfigResult, OpenIdConfiguration } from 'angular-auth-oidc-client';
import { AuthorizationGuard } from './services/auth.gaurd';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';

import { TradingAccountWizardService } from './services/trading-account-wizard.service';
import { DashboardComponent } from './dashboard/dashboard.component';
import { TradingAccountService } from './services/trading-account.service';
import { RoAccountComponent } from './ro-account/ro-account.component';
import { RoAccountService } from './services/ro-account.service';
import { NotificationsComponent } from './notifications/notifications.component';
import { EconomicCalendarComponent } from './economic-calendar/economic-calendar.component';
import { SupportIndexComponent } from './support/support-index/support-index.component';
import { NewTicketComponent } from './support/new-ticket/new-ticket.component';

import { SupportService } from './services/support.service';
import { SharedPipesModule } from './shared/pipes/shared-pipes.module';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { WithdrawListComponent } from './withdraw-list/withdraw-list.component';
import { ParticipationComponent } from './participation/index/participation.component';
import { ParticipationStartInvestorComponent } from './participation/start-investor/participation-start-investor.component';
import { ParticipationStartTraderComponent } from './participation/start-trader/participation-start-trader.component';
import { ParticipationService } from './services/participation.service';
import { TraderDetailsComponent } from './participation/trader-details/trader-details.component';
import { DepositListComponent } from './deposit-list/deposit-list.component';
import { ProfileComponent } from './profile/profile.component';
import { ProfileAvatarComponent } from './profile-avatar/profile-avatar.component';
import { ProfileChangePasswordComponent } from './profile-change-password/profile-change-password.component';
import { CreditsListComponent } from './credits-list/credits-list.component';
import { TellAFriendIndexComponent } from './tell-a-friend/tell-a-friend-index/tell-a-friend-index.component';
import { TellAFriendService } from './services/tell-a-friend.service';
import { UnderUpdateComponent } from './under-update/under-update.component';
import { TradeHistoryListComponent } from './trade-history-list/trade-history-list.component';
import { TraderTradeHistoryComponent } from './participation/trader-trade-history/trader-trade-history.component';


const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true
};


const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
    // Change this to your upload POST address:
    url: `${environment.apiBaseUrl}/api/TradingAccountRequest/Upload`,
    maxFilesize: 50,
    acceptedFiles: 'image/*',
    uploadMultiple: false,
    maxFiles: 2,

};
const l10nConfig: L10nConfig = {
    locale: {
        languages: [
            { code: 'en', dir: 'ltr' }
        ],
        language: 'en',
        storage: StorageStrategy.Cookie
    },
    translation: {
        providers: [
            { type: ProviderType.Static, prefix: './i18n/locale-' }
        ],
        caching: true,
        missingValue: 'No key'
    }
};
//console.log('environment.oidcConficPath', environment.oidcConficPath);
const oidc_configuration = environment.oidcConficPath;

export function loadConfig(oidcConfigService: OidcConfigService) {
    return () => oidcConfigService.load(oidc_configuration);
}


@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    SettingsPanelComponent,
    SidebarComponent,
    FooterComponent,
        WebtradeIndexComponent,
    Page404Component,
        UnauthorizedComponent,
        UnderUpdateComponent,
     DashboardComponent,
     Page500Component,
        LockscreenComponent,
        ProfileComponent,
        ProfileAvatarComponent,
        ProfileChangePasswordComponent,
    FaqComponent,
    Faq2Component,
    NewsGridComponent,
    TimelineComponent,
        SearchResultsComponent,
        ParticipationComponent,
        ParticipationStartInvestorComponent,
        ParticipationStartTraderComponent,
        TraderTradeHistoryComponent ,
        TraderDetailsComponent,
        CoursesIndexComponent,
        CourseBondsComponent,
        CourseForexComponent,
        CourseForexOptionsComponent,
        CourseEquitiesComponent,
        CourseCfdsComponent,
        CourseOptionsComponent,
        CourseFuturesComponent,
        CourseDetailsComponent,
        InvoiceComponent,
        ProductsIndexComponent,
        ProductCryptoComponent,
        ProductForexComponent,
        ProductStockComponent,
        ProductIndicesComponent,
        ProductCommodityComponent,
        PlatformsComponent,
        NotificationsComponent,
        EconomicCalendarComponent,
        SupportIndexComponent,
        NewTicketComponent,
        WithdrawListComponent,
        CreditsListComponent,
        TellAFriendIndexComponent,
        DepositListComponent,
        OrdersComponent,
        AccountWizardComponent,
        RoAccountComponent,
        TradeHistoryListComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
      NgbModule,
      PerfectScrollbarModule,
      TranslationModule.forRoot(l10nConfig),
    DragulaModule.forRoot(),
    ClipboardModule,
    ContextMenuModule.forRoot(),
      NouisliderModule,
      SharedPipesModule,
    CarouselModule,
    DropzoneModule,
    ColorPickerModule,
    AngularDateTimePickerModule,
    AmazingTimePickerModule,
    TagInputModule,
    FormWizardModule,
  NgxSummernoteModule,
    TinymceModule.withConfig({}),
    AceEditorModule,
    CodemirrorModule,
      ChartsModule,
      AuthModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    CustomFormsModule,
    Select2Module,
    MorrisJsModule,
    ChartistModule,
    DataTablesModule,
    SweetAlert2Module.forRoot(),
    ToastrModule.forRoot(),
    AgmCoreModule.forRoot({ apiKey: 'AIzaSyCnT63XUjqjPgXZ0lFTU_pdpfUX7swzTTM' }),
    BarRatingModule,
    FullCalendarModule,
      VgCoreModule,
      VgControlsModule,
      VgOverlayPlayModule,
      VgBufferingModule
  ],
    providers: [
        TradingAccountWizardService,
        TradingAccountService,
        ParticipationService,
        TellAFriendService,
        RoAccountService,
        SupportService,
        OidcConfigService,
        {
            provide: APP_INITIALIZER,
            useFactory: loadConfig,
            deps: [OidcConfigService],
            multi: true,
        },
        AuthorizationGuard,
        CookieService,
        {
            provide: DROPZONE_CONFIG,
            useValue: DEFAULT_DROPZONE_CONFIG
        },
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
        }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
    constructor(private oidcSecurityService: OidcSecurityService, private oidcConfigService: OidcConfigService,
        public l10nLoader: L10nLoader, public locale: LocaleService) {
        this.l10nLoader.load();
        this.locale.setCurrentLanguage('en');

        this.oidcConfigService.onConfigurationLoaded.subscribe((configResult: ConfigResult) => {

            // Use the configResult to set the configurations

            const config: OpenIdConfiguration = {
                stsServer: configResult.customConfig.stsServer,
                redirect_url: configResult.customConfig.redirect_url,
                client_id: configResult.customConfig.client_id,
                response_type: configResult.customConfig.response_type,
                scope: configResult.customConfig.scope,
                post_logout_redirect_uri: configResult.customConfig.post_logout_redirect_uri,
                start_checksession: configResult.customConfig.start_checksession,
                silent_renew: configResult.customConfig.silent_renew,
                silent_renew_url: configResult.customConfig.silent_renew_url,
                post_login_route: configResult.customConfig.startup_route,
                forbidden_route: configResult.customConfig.forbidden_route,
                unauthorized_route: configResult.customConfig.unauthorized_route,
                log_console_warning_active: configResult.customConfig.log_console_warning_active,
                log_console_debug_active: configResult.customConfig.log_console_debug_active,
                max_id_token_iat_offset_allowed_in_seconds: configResult.customConfig.max_id_token_iat_offset_allowed_in_seconds,
                history_cleanup_off: true

            };
          //  console.log('AppModule config', config);
            //const authWellKnownEndpoints: AuthWellKnownEndpoints = {
            //    issuer: environment.stsServerUrl+'/.well-known/openid-configuration/jwks',
            //    authorization_endpoint: environment.stsServerUrl +'/connect/authorize',
            //    token_endpoint: environment.stsServerUrl +'/connect/token',
            //    userinfo_endpoint: environment.stsServerUrl +'/connect/userinfo',
            //    end_session_endpoint: environment.stsServerUrl +'/connect/endsession',
            //    check_session_iframe: environment.stsServerUrl +'/connect/checksession',
            //    revocation_endpoint: environment.stsServerUrl +'/connect/revocation',
            //    introspection_endpoint: environment.stsServerUrl +'/connect/introspect',
            //};

            this.oidcSecurityService.setupModule(config, configResult.authWellknownEndpoints);
        });
    }
}