import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-product-indices',
    templateUrl: './product-indices.component.html',
    styleUrls: ['./product-indices.component.scss']
})
export class ProductIndicesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
