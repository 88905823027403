import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-course-cfds',
    templateUrl: './course-cfds.component.html',
    styleUrls: ['./course-cfds.component.scss']
})
export class CourseCfdsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
