import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

import * as signalR from '@aspnet/signalr';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserService } from '../../services/user.service';
import { ParticipationService } from '../../services/participation.service';
import { environment } from '../../../environments/environment';


@Component({
  selector: 'app-participation-start-investor',
    templateUrl: './participation-start-investor.component.html',
    styleUrls: ['./participation-start-investor.component.scss'],
})
export class ParticipationStartInvestorComponent implements OnInit {
    loading = false;
    investorParticipatesOfUser: any[] = [];
    approvedTraders: any[] = [];
    constructor(private toastr: ToastrService, private router: Router, private modalService: NgbModal,
        private participateService: ParticipationService, private fb: FormBuilder,
        private userService: UserService) { }

    ngOnInit() {

        const connectionApiAdmin = new signalR.HubConnectionBuilder()
            .configureLogging(signalR.LogLevel.Information)
            .withUrl(environment.adminBaseUrl + "/notifications")
            .build();
        connectionApiAdmin.start().then(function () {
            console.log('Connected hub admin');
        }).catch(function (err) {
            return console.error(err.toString());
        });

        connectionApiAdmin.on("prvcmd", (msg) => {
            const jsonMsg = JSON.parse(msg);

            switch (jsonMsg.command) {

                case "reloadInvestorProfile":
                    if (this.userService.userStatus && this.userService.userStatus.id === jsonMsg.uId) {

                        setTimeout(() => {
                            this.getInvestorParticipatesOfUser();
                        }, 1000);


                    }
                    break;

                default:
                    break;
            }
        });

        this.getInvestorParticipatesOfUser();
        this.getApprovedTraders();
    }

    getInvestorParticipatesOfUser() {
        this.participateService.getInvestorParticipatesOfUser().subscribe(res => {
            this.investorParticipatesOfUser = res;

           // console.log('getInvestorParticipatesOfUser', this.investorParticipatesOfUser);
        }, error => {
                console.log('getInvestorParticipatesOfUser error', error);
                this.investorParticipatesOfUser = [];
        });
    }

    getApprovedTraders() {
        this.participateService.getApprovedTraders().subscribe(res => {
            this.approvedTraders = res;

          //  console.log('getApprovedTraders', this.approvedTraders);
        }, error => {
                console.log('getApprovedTraders error', error);
                this.approvedTraders = [];
        });
    }
    cancelInvestRequest(id: string) {
        if (!id) {
            alert('id is null');
            return;
        } else {
            this.loading = true;
            this.participateService.cancelInvestRequest(id).subscribe(res => {
                this.loading = false;
                this.toastr.success(res.message);
                this.getInvestorParticipatesOfUser();

            }, error => {
                    this.loading = false;
                    console.log('cancelInvestRequest error', error);
               
            });
        }
    }
}
