import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { TradingAccountService } from '../services/trading-account.service';
import * as signalR from '@aspnet/signalr';
import { environment } from '../../environments/environment';
import { UserService } from '../services/user.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';


@Component({
  selector: 'app-credits-list',
    templateUrl: './credits-list.component.html',
    styleUrls: ['./credits-list.component.scss'],
})
export class CreditsListComponent implements OnInit {
    defAccount: any;
    accountId = '';
    credits: any[] = [];
  
    
    constructor(private toastr: ToastrService, private router: Router, private modalService: NgbModal,
        private route: ActivatedRoute,
        private tradingAccountService: TradingAccountService, public userService: UserService, private fb: FormBuilder) {
        this.accountId = this.route.snapshot.paramMap.get('id');
    }

    ngOnInit() {
        //this.userService.getUserStatus();
        const connectionApiAdmin = new signalR.HubConnectionBuilder()
            .configureLogging(signalR.LogLevel.Information)
            .withUrl(environment.adminBaseUrl + "/notifications")
            .build();
        connectionApiAdmin.start().then(function () {
            console.log('Connected hub admin');
        }).catch(function (err) {
            return console.error(err.toString());
        });

        connectionApiAdmin.on("prvcmd", (msg) => {
            const jsonMsg = JSON.parse(msg);

            switch (jsonMsg.command) {

                case "reloadDefAccount":
                    if (this.userService.userStatus && this.userService.userStatus.id === jsonMsg.uId) {

                        //   console.log('prvcmd resid reloadUserIranWalletBalance');
                        setTimeout(() => {
                            this.getDefAccount();
                        }, 1000);


                    }
                    break;
                case "reloadTransactionsOfDefAccount":
                    if (this.userService.userStatus && this.userService.userStatus.id === jsonMsg.uId) {

                        //   console.log('prvcmd resid reloadUserIranWalletBalance');
                        setTimeout(() => {
                            
                            this.getCredits();
                            

                        }, 1000);


                    }

                default:
                    break;
            }
        });
        this.getDefAccount();

    }

    getDefAccount() {
        if (this.accountId) {
            this.tradingAccountService.getTradingAccountOfUser(this.accountId)
                .subscribe(res => {
                    this.defAccount = res;
                    this.getCredits();
                }, error => {
                        this.defAccount = {};
                        this.credits = [];
                    console.log(error);
                });
        } else {
            this.defAccount = {};
            this.credits = [];
        }
       
    }
   
    getCredits() {
        if (this.defAccount) {
        this.tradingAccountService.getCreditsOfUserAccount(this.defAccount.id)
            .subscribe(res => {
               // console.log('getWithdraws', res);
                this.credits = res;
            }, error => {
                    this.credits = [];
                console.log(error);
            });
        }
        else {
            this.credits = [];
        }
    }
   

   

    

   
    
}
