import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { TradingAccountWizardService } from '../services/trading-account-wizard.service';
import { Router } from '@angular/router';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { environment } from '../../environments/environment';
import * as signalR from '@aspnet/signalr';
import { UserService } from '../services/user.service';


@Component({
  selector: 'app-account-wizard',
    templateUrl: './account-wizard.component.html',
    styleUrls: ['./account-wizard.component.scss']
})
export class AccountWizardComponent implements OnInit {
    isCompleted: boolean;
    frmStep1: FormGroup;
    apiBaseUrl = environment.apiBaseUrl;
    frontBaseUrl = environment.frontBaseUrl;
    submitted = false;
    showSpinner = false;
    birthDateStr = '';
    error = '';
    idVerificationConfig: DropzoneConfigInterface = {
        url: `${environment.apiBaseUrl}/api/TradingAccountRequest/UploadIdDoc`,
        maxFilesize: 50,
        acceptedFiles: 'image/*',
        uploadMultiple: false,
        maxFiles: 2,
      
       
    }
    addressVerificationConfig: DropzoneConfigInterface = {
        url: `${environment.apiBaseUrl}/api/TradingAccountRequest/UploadAddressDoc`,
        maxFilesize: 50,
        acceptedFiles: 'image/*',
        uploadMultiple: false,
        maxFiles: 2,

    }
    

    constructor(private formBuilder: FormBuilder, private toastr: ToastrService, private router: Router, private userService: UserService,
        private tradingAccountWizardService: TradingAccountWizardService) { }

    ngOnInit() {
        window.location.href = this.frontBaseUrl + '/open-real-account';
        const connectionApiAdmin = new signalR.HubConnectionBuilder()
            .configureLogging(signalR.LogLevel.Information)
            .withUrl(environment.adminBaseUrl + "/notifications")
            .build();
        connectionApiAdmin.start().then(function () {
            console.log('Connected hub admin');
        }).catch(function (err) {
            return console.error(err.toString());
        });

        connectionApiAdmin.on("prvcmd", (msg) => {
            const jsonMsg = JSON.parse(msg);

            switch (jsonMsg.command) {
                case "reloadWizardDetails":
                    if (this.userService.userStatus && this.userService.userStatus.id === jsonMsg.uId) {

                        setTimeout(() => {
                            this.createWizardForms();
                        }, 1000);
                    }
                default:
                    break;
            }
        });
        this.createWizardForms();
    }

    createWizardForms() {
        this.tradingAccountWizardService.getSteps()
            .subscribe(res => {
                // console.log('createWizardForms',res);
                this.birthDateStr = res.step1.birthDateStr;
                this.idVerificationConfig.headers = { "ownerId": res.ownerId };
                this.addressVerificationConfig.headers = { "ownerId": res.ownerId };
                this.frmStep1 = this.formBuilder.group({
                    title: [res.step1.title, [Validators.required]],
                    firstName: [res.step1.firstName, [Validators.required]],
                    middleName: [res.step1.middleName],
                    lastName: [res.step1.lastName, [Validators.required]],
                    birthDate: [res.step1.birthDate, [Validators.required]],
                    gender: [res.step1.gender, [Validators.required]],
                    address: [res.step1.address, [Validators.required]],
                    city: [res.step1.city, [Validators.required]],
                    country: [res.step1.country, [Validators.required]],
                    postalCode: [res.step1.postalCode, [Validators.required]],
                    livingTime: [res.step1.livingTime, [Validators.required]],
                    email: [res.step1.email, [Validators.required, Validators.email]],
                    telephone: [res.step1.telephone, [Validators.required]],
                    mobile: [res.step1.mobile, [Validators.required]],
                    employmentStatus: [res.step1.employmentStatus, [Validators.required]],
                    employerName: [res.step1.employerName],
                    businessNature: [res.step1.businessNature],
                    jobTitle: [res.step1.jobTitle],
                    businessName: [res.step1.businessName],
                    approximateAnnualIncome: [res.step1.approximateAnnualIncome, [Validators.required]],
                    approximateSavings: [res.step1.approximateSavings, [Validators.required]],
                    approximateAvailForTrade: [res.step1.approximateAvailForTrade, [Validators.required]],
                    fundsSource: [res.step1.fundsSource, [Validators.required]],
                    fundsSourceOther: [res.step1.fundsSourceOther],

                    understandRisksTrading: [res.step2.understandRisksTrading],
                    haveTradedForexPast2Years: [res.step2.haveTradedForexPast2Years],
                    haveTradedSharedBondsPast2Years: [res.step2.haveTradedSharedBondsPast2Years],
                    haveTradedCfdSpreadPast2Years: [res.step2.haveTradedCfdSpreadPast2Years],
                    forexTradesPerMonth: [res.step2.forexTradesPerMonth],
                    forexAverageMonthlyTradeVolume: [res.step2.forexAverageMonthlyTradeVolume],
                    sharesTradesPerMonth: [res.step2.sharesTradesPerMonth],
                    sharesAverageMonthlyTradeVolume: [res.step2.sharesAverageMonthlyTradeVolume],
                    cfdTradesPerMonth: [res.step2.cfdTradesPerMonth],
                    cfdAverageMonthlyTradeVolume: [res.step2.cfdAverageMonthlyTradeVolume],
                    haveUnderstandingDueInvolvementFinancialServices: [res.step2.haveUnderstandingDueInvolvementFinancialServices, [Validators.required]],
                    haveUnderstandingDueRelevantQualificationEducation: [res.step2.haveUnderstandingDueRelevantQualificationEducation, [Validators.required]],

                    tradingAccountType: [res.step3.tradingAccountType, [Validators.required]],
                    tradingPlatformMt5: [res.step3.tradingPlatformMt5],
                    tradingPlatformSystematic: [res.step3.tradingPlatformSystematic],
                    tradingPlatformRoDirectPro: [res.step3.tradingPlatformRoDirectPro],
                    tradingPlatformRoDirect: [res.step3.tradingPlatformRoDirect],
                    tradingAccountCurrency: [res.step3.tradingAccountCurrency, [Validators.required]],
                    ibNumber: [res.step3.ibNumber, [Validators.required]],
                    telephonePassword: [res.step3.telephonePassword, [Validators.required]],

                    idVerificationDoc: [res.step4.idVerificationDoc, [Validators.required]],
                    addressVerificationDoc: [res.step4.addressVerificationDoc, [Validators.required]],

                    declarationAccept1: [res.step5.declarationAccept1, [Validators.required]],
                    declarationAccept2: [res.step5.declarationAccept2, [Validators.required]],
                    declarationAccept3: [res.step5.declarationAccept3, [Validators.required]],
                    declarationAccept4: [res.step5.declarationAccept4, [Validators.required]],
                    editLocked: [res.editLocked]
                });
               
              
            }, error => {
                    if (error.error) {
                        if (error.error.statusCode === 401) {
                            this.toastr.error("Your session is ended. Please login again.")
                            this.router.navigate(['/login']);
                        }
                    }
        });
    }

    onAddressUploadSuccess(event) {
        this.frmStep1.controls.addressVerificationDoc.setValue(event[1].vPath);
    }

    onIdUploadSuccess(event) {
     //   console.log('onIdUploadSuccess',event);
        this.frmStep1.controls.idVerificationDoc.setValue(event[1].vPath);
    }

    onStep1Next(event) {
     
        let controls = this.frmStep1.controls;
       // console.log(this.frmStep1.value);
        let model = {
            title: controls.title.value,
            firstName: controls.firstName.value,
            middleName: controls.middleName.value,
            lastName: controls.lastName.value,
            birthDate: controls.birthDate.value,
            gender: controls.gender.value,
            address: controls.address.value,
            city: controls.city.value,
            country: controls.country.value,
            postalCode: controls.postalCode.value,
            livingTime: controls.livingTime.value,
            email: controls.email.value,
            telephone: controls.telephone.value,
            mobile: controls.mobile.value,
            employmentStatus: controls.employmentStatus.value,
            employerName: controls.employerName.value,
            businessNature: controls.businessNature.value,
            jobTitle: controls.jobTitle.value,
            businessName: controls.businessName.value,
            approximateAnnualIncome: controls.approximateAnnualIncome.value,
            approximateSavings: controls.approximateSavings.value,
            approximateAvailForTrade: controls.approximateAvailForTrade.value,
            fundsSource: controls.fundsSource.value,
            fundsSourceOther: controls.fundsSourceOther.value
        };
      //  console.log(model);
        this.tradingAccountWizardService.saveStep1(model)
            .subscribe(res => {
              //  console.log(res);
                this.toastr.success(res.message, '');
               
            }, error => {
                  //  console.log(error);
                    if (error.error.length > 0) {
                      //  console.log('iunja');
                        for (let [key, value] of Object.entries(error.error)) {
                            //  console.log(`${key}: ${value}`);
                            this.toastr.error(value.toString());
                        }
                    } else {
                        console.log(error.error);
                    }
                    //    for (let [key, value] of Object.entries(error.error.errors)) {
                    //        //  console.log(`${key}: ${value}`);
                    //        this.toastr.error(value.toString());
                    //    }
                    //}
                   
                  
        });
    }

    updatehaveTradedForexPast2Years(event) {
        
        this.frmStep1.controls.haveTradedForexPast2Years.setValue(event.currentTarget.checked);
    }
    updatehaveTradedSharedBondsPast2Years(event) {
        
        this.frmStep1.controls.haveTradedSharedBondsPast2Years.setValue(event.currentTarget.checked);
    }
    updatehaveTradedCfdSpreadPast2Years(event) {

        this.frmStep1.controls.haveTradedCfdSpreadPast2Years.setValue(event.currentTarget.checked);
    }
    updatetradingPlatformMt5(event) {

        this.frmStep1.controls.tradingPlatformMt5.setValue(event.currentTarget.checked);
    }
    updatetradingPlatformSystematic(event) {

        this.frmStep1.controls.tradingPlatformSystematic.setValue(event.currentTarget.checked);
    }

    updatetradingPlatformRoDirectPro(event) {

        this.frmStep1.controls.tradingPlatformRoDirectPro.setValue(event.currentTarget.checked);
    }
    updatetradingPlatformRoDirect(event) {

        this.frmStep1.controls.tradingPlatformRoDirect.setValue(event.currentTarget.checked);
    }
    updatedeclarationAccept1(event) {

        this.frmStep1.controls.declarationAccept1.setValue(event.currentTarget.checked);
    }
    updatedeclarationAccept2(event) {

        this.frmStep1.controls.declarationAccept2.setValue(event.currentTarget.checked);
    }
    updatedeclarationAccept3(event) {

        this.frmStep1.controls.declarationAccept3.setValue(event.currentTarget.checked);
    }
    updatedeclarationAccept4(event) {

        this.frmStep1.controls.declarationAccept4.setValue(event.currentTarget.checked);
    }

    

    onStep2Next(event) {
        let controls = this.frmStep1.controls;
        let model = {
            understandRisksTrading: controls.understandRisksTrading.value,
            haveTradedForexPast2Years: controls.haveTradedForexPast2Years.value,
            haveTradedSharedBondsPast2Years: controls.haveTradedSharedBondsPast2Years.value,
            haveTradedCfdSpreadPast2Years: controls.haveTradedCfdSpreadPast2Years.value,
            forexTradesPerMonth: controls.forexTradesPerMonth.value,
            forexAverageMonthlyTradeVolume: controls.forexAverageMonthlyTradeVolume.value,
            sharesTradesPerMonth: controls.sharesTradesPerMonth.value,
            sharesAverageMonthlyTradeVolume: controls.sharesAverageMonthlyTradeVolume.value,
            cfdTradesPerMonth: controls.cfdTradesPerMonth.value,
            cfdAverageMonthlyTradeVolume: controls.cfdAverageMonthlyTradeVolume.value,
            haveUnderstandingDueInvolvementFinancialServices: controls.haveUnderstandingDueInvolvementFinancialServices.value,
            haveUnderstandingDueRelevantQualificationEducation: controls.haveUnderstandingDueRelevantQualificationEducation.value,
        };

        this.tradingAccountWizardService.saveStep2(model)
            .subscribe(res => {
               // console.log(res);
                this.toastr.success(res.message, '');


            }, error => {

                    if (error.error.length > 0) {
                        //  console.log('iunja');
                        for (let [key, value] of Object.entries(error.error)) {
                            //  console.log(`${key}: ${value}`);
                            this.toastr.error(value.toString());
                        }
                    } else {
                        console.log(error.error);
                    }
            });
    }
    onStep3Next(event) {
        let controls = this.frmStep1.controls;
        let model = {
            tradingAccountType: controls.tradingAccountType.value,
            tradingPlatformMt5: controls.tradingPlatformMt5.value,
            tradingPlatformSystematic: controls.tradingPlatformSystematic.value,
            tradingPlatformRoDirectPro: controls.tradingPlatformRoDirectPro.value,
            tradingPlatformRoDirect: controls.tradingPlatformRoDirect.value,
            tradingAccountCurrency: controls.tradingAccountCurrency.value,
            ibNumber: controls.ibNumber.value,
            telephonePassword: controls.telephonePassword.value,
        };

        this.tradingAccountWizardService.saveStep3(model)
            .subscribe(res => {
               // console.log(res);
                this.toastr.success(res.message, '');


            }, error => {

                    if (error.error.length > 0) {
                        //  console.log('iunja');
                        for (let [key, value] of Object.entries(error.error)) {
                            //  console.log(`${key}: ${value}`);
                            this.toastr.error(value.toString());
                        }
                    } else {
                        console.log(error.error);
                    }
            });
    }
    onStep4Next(event) {
        let controls = this.frmStep1.controls;
        let model = {
            idVerificationDoc: controls.idVerificationDoc.value,
            addressVerificationDoc: controls.addressVerificationDoc.value,
          
        };
        console.log(model);
        this.tradingAccountWizardService.saveStep4(model)
            .subscribe(res => {
                // console.log(res);
                this.toastr.success(res.message, '');


            }, error => {

                    if (error.error.length > 0) {
                        //  console.log('iunja');
                        for (let [key, value] of Object.entries(error.error)) {
                            //  console.log(`${key}: ${value}`);
                            this.toastr.error(value.toString());
                        }
                    } else {
                        console.log(error.error);
                    }
            });
    }

    
    

    onComplete(event) {
        let controls = this.frmStep1.controls;
        let model = {
            declarationAccept1: controls.declarationAccept1.value,
            declarationAccept2: controls.declarationAccept2.value,
            declarationAccept3: controls.declarationAccept3.value,
            declarationAccept4: controls.declarationAccept4.value,
        };

        this.tradingAccountWizardService.saveStep5(model)
            .subscribe(res => {
                this.isCompleted = true;
                this.toastr.success(res.message, '');


            }, error => {
                    if (error.error.length > 0) {
                        //  console.log('iunja');
                        for (let [key, value] of Object.entries(error.error)) {
                            //  console.log(`${key}: ${value}`);
                            this.toastr.error(value.toString());
                        }
                    } else {
                        console.log(error.error);
                    }
            });
  
  }

}
