import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as signalR from '@aspnet/signalr';
import { CookieService } from 'ngx-cookie-service';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable } from 'rxjs';
import { TranslationService, LocaleService, Language } from 'angular-l10n';
import { UserStatus } from './interfaces';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    @Language() lang: string;

    headers: HttpHeaders;
    public userStatus: UserStatus | null;

    constructor(private http: HttpClient, public locale: LocaleService, public translation: TranslationService,
        private oidcSecurityService: OidcSecurityService,
        private cookieService: CookieService) {
        setTimeout(() => {
            this.getUserStatus();

        }, 1000);


    }
    private setHeaders() {
        this.headers = new HttpHeaders();
        this.headers = this.headers.set('Content-Type', 'application/json');
        this.headers = this.headers.set('Accept', 'application/json');

        const token = this.oidcSecurityService.getToken();
        if (token !== '') {
            const tokenValue = 'Bearer ' + token;
            this.headers = this.headers.append('Authorization', tokenValue);
        }
    }

    getUserStatus() {
        this.setHeaders();
        return this.http.get(environment.apiBaseUrl + '/api/users/GetAccountStatus', { headers: this.headers })
            .subscribe(res => {

                this.userStatus = <UserStatus>res;
            //    console.log('userStatus', this.userStatus);
            }, error => {
             //   console.log('this.getUserStatus error', error);
                this.userStatus = null;
                setTimeout(() => {
                    this.getUserStatus();

                }, 2000);
            });

    }

  
    getUserVerifyStatus(): Observable<any> {
        this.setHeaders();
        return this.http
            .get(environment.apiBaseUrl + '/api/users/GetUserStatus', { headers: this.headers }) as Observable<any>;
    }
    
    getNewSecurityToken(): Observable<any> {
        this.setHeaders();
        return this.http
            .get(environment.apiBaseUrl + '/api/users/GetSecurityToken', { headers: this.headers }) as Observable<any>;
    }
    changePassword(model: any): Observable<any> {
        this.setHeaders();
        return this.http
            .post(environment.stsServerUrl + '/api/account/ChangePassword', model, { headers: this.headers }) as Observable<any>;
    }

    sendSecurityToken(model: any): Observable<any> {
        this.setHeaders();
        return this.http
            .post(environment.stsServerUrl + '/api/account/SendSecurityTokenForChangePassword',model, { headers: this.headers }) as Observable<any>;
    }
    getProfileInfo(): Observable<any> {
        this.setHeaders();
        return this.http
            .get(environment.apiBaseUrl + '/api/users/GetProfileInfo', { headers: this.headers }) as Observable<any>;
    }
    GetUnderUpdateInfo(): Observable<any> {
       this.setHeaders();
        return this.http
            .get(environment.apiBaseUrl + '/api/users/GetUnderUpdateInfo', { headers: this.headers }) as Observable<any>;
    }
    
}