// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    //production: false,
    //apiBaseUrl: 'https://localhost:44301',
    //frontBaseUrl: 'https://localhost:44304',
    //stsServerUrl: 'https://localhost:44300',
    //appBaseUrl: 'https://localhost:44302',
    //adminBaseUrl: 'https://localhost:44303',
    //oidcConficPath: '/assets/auth.clientConfiguration.json',

   production: true,
   apiBaseUrl: 'https://api.raffleoption.com',
   frontBaseUrl: 'https://raffleoption.com',
   stsServerUrl: 'https://idp.raffleoption.com',
   appBaseUrl: 'https://app.raffleoption.com',
   adminBaseUrl: 'https://admin.raffleoption.com',
   oidcConficPath: '/assets/auth.clientConfiguration.prod.json',

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
