import { Component, OnInit } from '@angular/core';
import { UserService } from '../services/user.service';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-profile-avatar',
    templateUrl: './profile-avatar.component.html',
    styleUrls: ['./profile-avatar.component.scss']
})

export class ProfileAvatarComponent implements OnInit {
    avatarVerificationConfig: DropzoneConfigInterface = {
        url: `${environment.apiBaseUrl}/api/Users/UploadAvatar`,
        maxFilesize: 50,
        acceptedFiles: 'image/*',
        uploadMultiple: false,
        maxFiles: 1,
        headers: { 'ownerId': '212' },
        
        


    }
  
    constructor(public userService: UserService, public oidcSecurityService: OidcSecurityService,
        private http: HttpClient) { }

    ngOnInit() {

    }

    signup = new FormGroup({
       
        image: new FormControl(null, [Validators.required])
    });
    processFile(imageInput: any) {
        const file: File = imageInput.files[0];
        const reader = new FileReader();

        reader.addEventListener('load', (event: any) => {

            const formData = new FormData();

            formData.append('image', file);
            formData.append('ownerId', '222');
            return this.http.post(`${environment.apiBaseUrl}/api/Users/UploadAvatar`, formData).subscribe(
                (res) => {
                    console.log(res);
                },
                (err) => {
                    console.log(err);
                })
        });

        reader.readAsDataURL(file);
    }
    onAvatarUploadSuccess(event) {
        console.log(event);
       // this.userService.getUserStatus();
    }
 
}
