import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-course-bonds',
    templateUrl: './course-bonds.component.html',
    styleUrls: ['./course-bonds.component.scss']
})
export class CourseBondsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
