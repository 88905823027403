import { Component, OnInit } from '@angular/core';
import { UserService } from '../services/user.service';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';


@Component({
    selector: 'app-profile-change-password',
    templateUrl: './profile-change-password.component.html',
    styleUrls: ['./profile-change-password.component.scss']
})

export class ProfileChangePasswordComponent implements OnInit {
    frmChangePassword: FormGroup;
    submitted = false;
    secureTokenSent = false;
    errors: Array<string> = [];
        
    constructor(public userService: UserService, public oidcSecurityService: OidcSecurityService,
        private formBuilder: FormBuilder, private toastr: ToastrService) { }

    ngOnInit() {
        this.createChangePasswordForm();
    }
   
    createChangePasswordForm() {
        this.secureTokenSent = false;
        this.submitted = false;
        this.frmChangePassword = this.formBuilder.group({

            oldPassword: ['', [Validators.required]],
            newPassword: ['', [Validators.required, Validators.minLength(8)]],
            confirmNewPassword: ['', [Validators.required]],
            securityToken:['']
        });
    }

    getSecurityToken() {
        this.errors = [];
        this.submitted = true;
        if (!this.frmChangePassword.valid) {
            this.toastr.error('Please complete the form');
            return;
        }
        let model = {
            id: this.userService.userStatus.id,
            oldPassword: this.frmChangePassword.value.oldPassword,
            newPassword: this.frmChangePassword.value.newPassword,
            confirmNewPassword: this.frmChangePassword.value.confirmNewPassword,
            securityToken: this.frmChangePassword.value.securityToken
        };
        this.userService.sendSecurityToken(model).subscribe(res => {
          
            this.toastr.success(res.message);
            this.secureTokenSent = true;
            this.submitted = false;
        }, error => {
                this.submitted = false;
            // console.log(error);
            if (error.error.errors) {
                error.error.errors.forEach(d => {
                    this.toastr.error(d);
                })
            } else {
                this.toastr.error(error.error.error);
            }
        });
    }

    onSubmit() {
        this.errors = [];
        this.submitted = true;
        if (!this.frmChangePassword.valid) {
            this.toastr.error('Please complete the form');
            return;
        }
        if (!this.frmChangePassword.value.securityToken) {
            this.toastr.error('Security token is required.');
            return;
        }

        let model = {
            id: this.userService.userStatus.id,
            oldPassword: this.frmChangePassword.value.oldPassword,
            newPassword: this.frmChangePassword.value.newPassword,
            confirmNewPassword: this.frmChangePassword.value.confirmNewPassword,
            securityToken: this.frmChangePassword.value.securityToken
        };

        this.userService.changePassword(model).subscribe(res => {
            this.submitted = false;
            console.log('onSubmit res', res);
            this.toastr.success(res.message);
            this.secureTokenSent = false;
            this.createChangePasswordForm();
            this.toastr.info('You will be signed out in 3 seconds')
            setTimeout(() => {
                this.oidcSecurityService.logoff();
            }, 3000);
            
        }, error => {
                this.submitted = false;
                console.log('onSubmit error',error);
            if (error.error.errors) {
                error.error.errors.forEach(d => {
                    this.toastr.error(d);
                    this.errors.push(d);
                })
            } else {
                this.toastr.error(error.error.error);
            }
        });
        
    }
}
