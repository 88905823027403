import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-product-forex',
    templateUrl: './product-forex.component.html',
    styleUrls: ['./product-forex.component.scss']
})
export class ProductForexComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
