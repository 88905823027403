import { Component } from '@angular/core';
import { Router } from '@angular/router';


import { UserService } from '../services/user.service';


@Component({
	selector: 'app-under-update',
    templateUrl: './under-update.component.html',
    styleUrls: ['./under-update.component.scss']
})
export class UnderUpdateComponent {
    title = "Updating...";
    message = '';
    constructor(private userService: UserService, private router: Router) { }
   

 

    getMessage() {

        this.userService.GetUnderUpdateInfo().subscribe(res => {
            if (res.underUpdateModeActive === false)
                this.router.navigate(['/dashboard']);
            this.message = res.underUpdateMessage;
        }, error => {
                this.router.navigate(['/unauthorized']);
        });
    }

    ngOnInit() {
        this.getMessage();
    }

}
