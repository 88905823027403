
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { OidcSecurityService } from 'angular-auth-oidc-client';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class TellAFriendService {
    headers: HttpHeaders;
    constructor(private http: HttpClient, private oidcSecurityService: OidcSecurityService) { }

    private setHeaders() {
        this.headers = new HttpHeaders();
        this.headers = this.headers.set('Content-Type', 'application/json');
        this.headers = this.headers.set('Accept', 'application/json');

        const token = this.oidcSecurityService.getToken();
        if (token !== '') {
            const tokenValue = 'Bearer ' + token;
            this.headers = this.headers.append('Authorization', tokenValue);
        }
    }

    GetSettings(): Observable<any> {
        this.setHeaders();
        return this.http
            .get(environment.apiBaseUrl + '/api/tell-a-friend/GetSettings', { headers: this.headers }) as Observable<any>;
    }
}
