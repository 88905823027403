import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { interval, Subscription } from 'rxjs';



import { User } from './interfaces';
import { environment } from '../../environments/environment';
import { OidcSecurityService } from 'angular-auth-oidc-client';

@Injectable({ providedIn: 'root' })
export class TradingAccountWizardService implements OnDestroy {
    headers: HttpHeaders;
    constructor(private http: HttpClient, private oidcSecurityService: OidcSecurityService) { }

    private setHeaders() {
        this.headers = new HttpHeaders();
        this.headers = this.headers.set('Content-Type', 'application/json');
        this.headers = this.headers.set('Accept', 'application/json');

        const token = this.oidcSecurityService.getToken();
        if (token !== '') {
            const tokenValue = 'Bearer ' + token;
            this.headers = this.headers.append('Authorization', tokenValue);
        }
    }
    getSteps(): any {
        this.setHeaders();
        return this.http.get<any>(`${environment.apiBaseUrl}/api/TradingAccountRequest/GetSteps`, { headers: this.headers });

    }
    saveStep1(model: any): any {
        this.setHeaders();
        return this.http.post<any>(`${environment.apiBaseUrl}/api/TradingAccountRequest/SaveStep1`, model, { headers: this.headers });
    }
    saveStep2(model: any): any {
        this.setHeaders();
        return this.http.post<any>(`${environment.apiBaseUrl}/api/TradingAccountRequest/SaveStep2`, model, { headers: this.headers });
    }
    saveStep3(model: any): any {
        this.setHeaders();
        return this.http.post<any>(`${environment.apiBaseUrl}/api/TradingAccountRequest/SaveStep3`, model, { headers: this.headers });
    }
    saveStep4(model: any): any {
        this.setHeaders();
        return this.http.post<any>(`${environment.apiBaseUrl}/api/TradingAccountRequest/SaveStep4`, model, { headers: this.headers });
    }
    saveStep5(model: any): any {
        this.setHeaders();
        return this.http.post<any>(`${environment.apiBaseUrl}/api/TradingAccountRequest/SaveStep5`, model, { headers: this.headers });
    }
    
 

    ngOnDestroy() {
        
    }

  
}