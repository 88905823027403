import { Component, OnInit } from '@angular/core';



@Component({
  selector: 'app-webtrade-index',
    templateUrl: './webtrade-index.component.html',
    styleUrls: ['./webtrade-index.component.scss'],
 
})
export class WebtradeIndexComponent implements OnInit {
  
    constructor() {
   
  }

    ngOnInit() {
       
  }

}
