import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-course-equities',
    templateUrl: './course-equities.component.html',
    styleUrls: ['./course-equities.component.scss']
})
export class CourseEquitiesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
