import {  FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, HostListener, ViewChild, OnDestroy } from '@angular/core';

import { Router } from '@angular/router';

import * as signalR from '@aspnet/signalr';


import { ToastrService } from 'ngx-toastr';
import { Subscription, Subject } from 'rxjs';

import { Language } from 'angular-l10n';


import { environment } from '../../../environments/environment';
import { UserStatus } from '../../services/interfaces';
import { UserService } from '../../services/user.service';
import { TellAFriendService } from '../../services/tell-a-friend.service';


@Component({
  selector: 'app-tell-a-friend-index',
    templateUrl: './tell-a-friend-index.component.html',
    styleUrls: ['./tell-a-friend-index.component.scss'],
})
export class TellAFriendIndexComponent implements OnInit {
    @Language() lang: string;
    hasError = false;
    errorText = "";
    commissionText: string;
    tellFriendSettings: any;
    userStatus: UserStatus | null;
 
   
    constructor(private toastr: ToastrService,
        private formBuilder: FormBuilder,
        public userService: UserService,
        public router: Router,
        private tellAFriendService: TellAFriendService) { }

  ngOnInit() {

      const connection = new signalR.HubConnectionBuilder()
          .configureLogging(signalR.LogLevel.Information)
          .withUrl(environment.adminBaseUrl + "/notifications")
          .build();
      connection.start().then(function () {
          console.log('Connected admin hub!');
      }).catch(function (err) {
          return console.error(err.toString());
      });
      connection.on("pubcmd", (msg) => {
          const jsonMsg = JSON.parse(msg);
          
          switch (jsonMsg.command) {
              case "reloadTellAFriendSettings":
                  this.getTellAFriendSettings();

                  break;
             
              default:
                  break;
          }
      });

      connection.on("prvcmd", (msg) => {
          const jsonMsg = JSON.parse(msg);

          switch (jsonMsg.command) {

              case "reloadTellAFriendSettings":
                  if (this.userStatus && this.userStatus.id === jsonMsg.uId) {

                      setTimeout(() => {
                          this.getTellAFriendSettings();
                      }, 1000);
                  }
                  break;

              default:
                  break;
          }
      });


      const connectionIdp = new signalR.HubConnectionBuilder()
          .configureLogging(signalR.LogLevel.Information)
          .withUrl(environment.stsServerUrl + "/notifications")
          .build();

      connectionIdp.start().then(function () {
          console.log('Connected sts hub!');
      }).catch(function (err) {
          return console.error(err.toString());
      });
      
      connectionIdp.on("prvcmd", (msg) => {
          const jsonMsg = JSON.parse(msg);

          switch (jsonMsg.command) {

              case "reloadTellAFriendSettings":
                  if (this.userStatus && this.userStatus.id === jsonMsg.uId) {

                      setTimeout(() => {
                          this.getTellAFriendSettings();
                      }, 1000);
                  }
                  break;

              default:
                  break;
          }
      });

      this.getUserStatus();
      this.getTellAFriendSettings();
    }
    getTellAFriendSettings() {
        this.tellAFriendService.GetSettings()
            .subscribe(res => {
                this.hasError = false;
                this.tellFriendSettings = res;
                console.log('getTellAFriendSettings', res);
            },
                error => {
                    console.log('getTellAFriendSettings error', error);
                    this.tellFriendSettings = null;
                    this.hasError = true;
                    this.errorText = error.error.message;
                    
                });
    }
    getUserStatus() {
        this.userStatus = this.userService.userStatus;

    }
  

    copyToClipboard(text): void {
        
            let listener = (e: ClipboardEvent) => {
                e.clipboardData.setData('text/plain', (text));
                this.toastr.info("Copied to clipboard");
                e.preventDefault();
            };

            document.addEventListener('copy', listener);
            document.execCommand('copy');
            document.removeEventListener('copy', listener);
        }

    
}
