import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../environments/environment';



@Component({
  selector: 'app-course-details',
    templateUrl: './course-details.component.html',
    styleUrls: ['./course-details.component.scss']
})
export class CourseDetailsComponent implements OnInit {
    id: number = 0;
    public apiBaseUrl = environment.apiBaseUrl;

    constructor(private route: ActivatedRoute) { }

    ngOnInit() {

        this.id = parseInt(this.route.snapshot.paramMap.get("id"));
  }

}
