import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, HostListener, ViewChild, OnDestroy } from '@angular/core';
import * as signalR from '@aspnet/signalr';

import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription, Subject } from 'rxjs';
import { debounceTime, find } from 'rxjs/operators';
import { Language, TranslationService, LocaleService } from 'angular-l10n';

import { TicketListModel, TicketDetailsModel, TicketMessageModel } from '../../services/interfaces';
import { SupportService } from '../../services/support.service';
import { environment } from '../../../environments/environment';
import { UserService } from '../../services/user.service';


@Component({
  selector: 'app-support-index',
    templateUrl: './support-index.component.html',
    styleUrls: ['./support-index.component.scss']
    
})
export class SupportIndexComponent implements OnInit, OnDestroy {
    @Language() lang: string;
    private modelChanged: Subject<string> = new Subject<string>();
    private subscription: Subscription;
    debounceTime = 500;
    searchText: string;
    loading = false;
 //   btnReplyLoading= false;
//  public ticketDepartments: Array<DepartmentListModel>;
    public tickets: Array<TicketListModel>;
    public ticketsForSearch: Array<TicketListModel>;
   public ticketMessages: Array<TicketMessageModel>=[];
    currentTicketId = '';
    public currentTicket: TicketListModel;
 //   public newTicketMessage: FormGroup;
 //   public showNewTicketForm  = false;
 //   public newTicket: FormGroup;
    public replyForm: FormGroup;
    
    isReplyFormActive = false;
    constructor(
        private toastr: ToastrService,
        private userService: UserService,
    private formBuilder: FormBuilder,
        public router: Router, public locale: LocaleService,
        public translation: TranslationService, 
        private supportService: SupportService) {
      
    }

    ngOnInit() {
        const connectionApiAdmin = new signalR.HubConnectionBuilder()
            .configureLogging(signalR.LogLevel.Information)
            .withUrl(environment.adminBaseUrl + "/notifications")
            .build();
        connectionApiAdmin.start().then(function () {
            console.log('Connected hub admin');
        }).catch(function (err) {
            return console.error(err.toString());
        });

        connectionApiAdmin.on("prvcmd", (msg) => {
            const jsonMsg = JSON.parse(msg);

            switch (jsonMsg.command) {
                case "reloadUserSupportTickets":
                    if (this.userService.userStatus && this.userService.userStatus.id === jsonMsg.uId) {

                        setTimeout(() => {
                            this.loadUserTickets(undefined);
                        }, 1000);
                    }
                default:
                    break;
            }
        });
        this.loadUserTickets(undefined);
      this.subscription = this.modelChanged
          .pipe(
              debounceTime(this.debounceTime),
          )
          .subscribe(() => {
              this.filterTickets();

          });
    }

    filterTickets() {
     
        this.tickets = this.ticketsForSearch
            .filter(d => d.subject.includes(this.searchText) || d.refCode.includes(this.searchText));

    }

    inputChanged() {
        
        this.modelChanged.next();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    createReplyForm() {
        
        this.isReplyFormActive = true;
        this.replyForm = this.formBuilder.group({
           
            description: ['', [Validators.required, Validators.maxLength(500)]],
            fileUrl: ['']
        });
    }

    loadUserTickets(id) {
        
        this.loading = true;
    
    this.supportService.getUserTickets()
        .subscribe(data => {
            this.loading = false;
            this.tickets = data;
            console.log('loadUserTickets',this.tickets);
            this.ticketsForSearch = data;
            if (this.tickets === undefined || this.tickets.length === 0) {

                this.router.navigateByUrl("/support/new-ticket");
           //     this.createNewTicketForm();
  
            }
          else {
              if (id) {
                    let finded = this.tickets.find(d => d.id === id);
                  if (finded) {
                      this.currentTicket = finded;
                      this.currentTicketId = id;
                      this.getCurTicket();
                  } else {
                      this.currentTicket = this.tickets[0];
                      this.currentTicketId = this.tickets[0].id;
                      this.getCurTicket();
                   }
               }
              else {
                  this.currentTicket = this.tickets[0];

                  this.currentTicketId = this.tickets[0].id;
                  this.getCurTicket();
                }
           
        }
       },
            error => {
                this.loading = false;
         console.log('error in getUserTickets', error);
     
     });

    }

    getCurTicket() {
        if (this.currentTicketId) {
            this.loading = true;
            this.supportService.getMyTicketInfoById(this.currentTicketId).subscribe(res => {
                this.loading = false;
                this.ticketMessages = res;
            //    this.createReplyForm();
             //   console.log('getCurTicket', this.ticketMessages);
            }, error => {
                this.loading = false;
                this.toastr.error(error.error);
            })
        }
    }
    closeTicket() {
        if (this.currentTicket && this.currentTicket.status !== 2) {
            let tempId = this.currentTicket.id;
            this.loading = true;
            this.supportService.closeTicket({ id: this.currentTicket.id })
              .subscribe(data => {
                   this.loading = false;
                   this.toastr.success(data.message);
                this.loadUserTickets(tempId);
               },
                  error => {
                       this.loading = false;
                       console.log('error in closeTicket', error);

                    });
       }
   }

  //onUploadedNewTicket(fileUrl: string) {
  //  this.newTicket.patchValue({files: fileUrl});
  //}
  //  loadTicketDepartments() {
  //      this.loading = true;
  //  this.supportService.getActiveTicketDepartments()
  //      .subscribe(data => {
  //          this.loading = false;
  //    this.ticketDepartments = data;
  //   },
  //          error => {
  //              this.loading = false;

  //       console.log('error in loadTicketDepartments', error);
    
  //   });
  //}

//    createNewTicketForm() {
//        this.loadTicketDepartments();
//  console.log('createNewTicketForm', this.newTicket);
//  this.newTicket = this.formBuilder.group({
//    subject: ['', [Validators.required, Validators.maxLength(50)]],
//    description: ['', [Validators.required, Validators.maxLength(500)]],
//    departmentId: ['', [Validators.required]],
//    fileUrl: ['']
//});
//  this.showNewTicketForm = true;
//  this.tickets = [];
//  }


  //onSubmitTicket() {
  //  const controls = this.newTicket.controls;
  //    this.loading = true;

  //  // stop here if form is invalid
  //  if (this.newTicket.invalid) {
  //      this.loading = false;
  //      this.toastr.error('فرم به طور کامل تکمیل نشده است');
  //      return;
  //  }
  //  const model = {
  //    Subject: controls.subject.value,
  //    Description: controls.description.value,
  //    FileUrl: controls.fileUrl.value,
  //     DepartmentId: controls.departmentId.value,
    
  //     };
  //     console.log('model', model);
  //     this.supportService.addUserTicket(model).subscribe(res => {
  //         this.loading = false;
  //         this.toastr.success(res.Message);
       
  //         this.showNewTicketForm = false;
  //         this.loadUserTickets(res.Ticket.Id);

  //      }, err => {
  //             this.loading = false;
  //             this.toastr.error(err.error);
        
  //     });
  //    this.loading = false;
  //  }

   onSubmitReply() {
      //  this.btnReplyLoading = true;
        const controls = this.replyForm.controls;
        this.loading = true;

  //      // stop here if form is invalid
        if (this.replyForm.invalid) {
          this.loading = false;
            this.toastr.error('Please complete reply form');
          return;
       }
        const model = {
          
            description: controls.description.value,
          fileUrl: controls.fileUrl.value,
           ticketId: this.currentTicket.id
       };
      //  console.log('model', model);
        this.supportService.addReply(model).subscribe(res => {
          //  console.log('res', res);
            this.loading = false;
            this.toastr.success(res.message);
            this.isReplyFormActive = false;
           let tempTicket = this.currentTicket;
            this.loadUserTickets(tempTicket.id);
            this.showTicket(tempTicket.id);
        }, err => {
            this.loading = false;
            this.toastr.error(err.error);

        });
       this.loading = false;
    }
  //  cancelNewTicket() {
  //     // let temp = this.currentTicket;
  //      this.showNewTicketForm = false;
  //      this.isReplyFormActive = false;
  //      this.loadUserTickets(undefined);
       
  //  }
  //  cancelReply() {
  //      let temp = this.currentTicket;
  //      this.isReplyFormActive = false;
  //      this.loadUserTickets(temp.Id);
  //  }
  public showTicket(id) {
      if (id) {
          let finded = this.tickets.find(d => d.id === id);
          if (finded) {
              this.currentTicket = finded;

              this.currentTicketId = finded.id;
              this.getCurTicket();
          } else {
              this.currentTicket = this.tickets[0];

              this.currentTicketId = this.tickets[0].id;
              this.getCurTicket();
          }
      }
      else {
          this.currentTicket = this.tickets[0];

          this.currentTicketId = this.tickets[0].id;
          this.getCurTicket();
      }
      //this.router.navigate(['/support/ticket',  id]);
    }
    closeReplyForm() {
        
        this.isReplyFormActive = false;
        this.showTicket(this.currentTicket.id);
    }
   
 

}
