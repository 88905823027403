import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { TradingAccountService } from '../services/trading-account.service';
import * as signalR from '@aspnet/signalr';
import { environment } from '../../environments/environment';
import { UserService } from '../services/user.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';


@Component({
  selector: 'app-withdraw-list',
    templateUrl: './withdraw-list.component.html',
    styleUrls: ['./withdraw-list.component.scss'],
})
export class WithdrawListComponent implements OnInit {
    defAccount: any;
    accountId = '';
    withdraws: any[] = [];
    firstStepMsg = '';
    frmWithdraw: FormGroup;
    securityTokenSent = false;
    
    constructor(private toastr: ToastrService, private router: Router, private modalService: NgbModal,
        private route: ActivatedRoute,
        private tradingAccountService: TradingAccountService, public userService: UserService, private fb: FormBuilder) {
        this.accountId = this.route.snapshot.paramMap.get('id');
    }

    ngOnInit() {
        //this.userService.getUserStatus();
        const connectionApiAdmin = new signalR.HubConnectionBuilder()
            .configureLogging(signalR.LogLevel.Information)
            .withUrl(environment.adminBaseUrl + "/notifications")
            .build();
        connectionApiAdmin.start().then(function () {
            console.log('Connected hub admin');
        }).catch(function (err) {
            return console.error(err.toString());
        });

        connectionApiAdmin.on("prvcmd", (msg) => {
            const jsonMsg = JSON.parse(msg);

            switch (jsonMsg.command) {

                case "reloadDefAccount":
                    if (this.userService.userStatus && this.userService.userStatus.id === jsonMsg.uId) {

                        //   console.log('prvcmd resid reloadUserIranWalletBalance');
                        setTimeout(() => {
                            this.getDefAccount();
                        }, 1000);


                    }
                    break;
                case "reloadTransactionsOfDefAccount":
                    if (this.userService.userStatus && this.userService.userStatus.id === jsonMsg.uId) {

                        //   console.log('prvcmd resid reloadUserIranWalletBalance');
                        setTimeout(() => {
                            
                            this.getWithdraws();
                            

                        }, 1000);


                    }

                default:
                    break;
            }
        });
        this.getDefAccount();

    }

    getDefAccount() {
        if (this.accountId) {
            this.tradingAccountService.getTradingAccountOfUser(this.accountId)
                .subscribe(res => {
                    this.defAccount = res;
                    this.getWithdraws();
                }, error => {
                        this.defAccount = {};
                        this.withdraws = [];
                    console.log(error);
                });
        } else {
            this.defAccount = {};
            this.withdraws = [];
        }
       
    }
   
    getWithdraws() {
        if (this.defAccount) {
        this.tradingAccountService.getWithdrawsOfUserAccount(this.defAccount.id)
            .subscribe(res => {
                console.log('getWithdraws', res);
                this.withdraws = res;
            }, error => {
                    this.withdraws = [];
                console.log(error);
            });
        }
        else {
            this.withdraws = [];
        }
    }
   

    openModal(content) {
        this.securityTokenSent = false;
        this.firstStepMsg = '';
        this.frmWithdraw = this.fb.group({
            amount: ['', Validators.required],
            securityToken: [''],
        });
        this.modalService.open(content);
    }

   
    mt5WithdrawNextClick() {
        if (this.frmWithdraw.invalid) {
            this.toastr.error('Please complete the form');
            return;
        }
        let model = {
            accountId: this.defAccount.id,
            accountNumber: this.defAccount.accountNumber,
            amount: this.frmWithdraw.value.amount
        };

        this.tradingAccountService.submitWithdrawRequestFirstStep(model).subscribe(res => {
            this.firstStepMsg = res.message;
            
            this.securityTokenSent = true;
            this.toastr.success(res.message);
        }, error => {
            // console.log(error);
            if (error.error.errors) {
                error.error.errors.forEach(d => {
                    this.toastr.error(d);
                })
            } else {
                this.toastr.error(error.error.error);
            }
        });
        return;
    }

    onSubmitWithdrawRequest() {
        if (this.frmWithdraw.invalid) {
            this.toastr.error('Please complete the form');
            return;
        }
        if (!this.frmWithdraw.value.securityToken) {
            this.toastr.error('Security token is required.');
            return;
        }

        let model = {
            accountId: this.defAccount.id,
            accountNumber: this.defAccount.accountNumber,
            amount: this.frmWithdraw.value.amount,
            securityToken: this.frmWithdraw.value.securityToken
        };

        this.tradingAccountService.addWithdrawRequest(model).subscribe(res => {
            this.modalService.dismissAll();
            this.toastr.success(res.message);
            this.securityTokenSent = false;
            this.firstStepMsg = '';
            this.getWithdraws();
        }, error => {
            // console.log(error);
            if (error.error.errors) {
                error.error.errors.forEach(d => {
                    this.toastr.error(d);
                })
            } else {
                this.toastr.error(error.error.error);
            }
        });
    }

    
}
