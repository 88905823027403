import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-products-index',
    templateUrl: './products-index.component.html',
    styleUrls: ['./products-index.component.scss']
})
export class ProductsIndexComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
