import { Component, OnInit } from '@angular/core';
import { UserService } from '../services/user.service';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { TradingAccountWizardService } from '../services/trading-account-wizard.service';
import * as signalR from '@aspnet/signalr';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})

export class ProfileComponent implements OnInit {
    profileInfo: any = {};
    editWizardUrl = environment.frontBaseUrl + '/open-real-account';
    constructor(public userService: UserService, public oidcSecurityService: OidcSecurityService,
        private tradingAccountWizardService: TradingAccountWizardService) { }

    ngOnInit() {
        
        const connectionApiAdmin = new signalR.HubConnectionBuilder()
            .configureLogging(signalR.LogLevel.Information)
            .withUrl(environment.adminBaseUrl + "/notifications")
            .build();
        connectionApiAdmin.start().then(function () {
            console.log('Connected hub admin');
        }).catch(function (err) {
            return console.error(err.toString());
        });

        connectionApiAdmin.on("prvcmd", (msg) => {
            const jsonMsg = JSON.parse(msg);

            switch (jsonMsg.command) {
                case "reloadWizardDetails":
                    if (this.userService.userStatus && this.userService.userStatus.id === jsonMsg.uId) {

                        setTimeout(() => {
                            this.getProfileDetails();
                        }, 1000);
                    }
                default:
                    break;
            }
        });
        this.getProfileDetails();
    }
    getProfileDetails() {
        this.userService.getProfileInfo()
            .subscribe(res => {
                console.log('getProfileDetails',res);
                this.profileInfo = res;
            }, error => {
                    console.log(error);
                //if (error.error) {
                //    if (error.error.statusCode === 401) {
                //        this.toastr.error("Your session is ended. Please login again.")
                //        this.router.navigate(['/login']);
                //    }
                //}
            });
    }



}
