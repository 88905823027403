
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError, count } from 'rxjs/operators';

import { OidcSecurityService } from 'angular-auth-oidc-client';
import { environment } from '../../environments/environment';
import { TicketListModel, DepartmentListModel, TicketMessageListForAdminModel, AddEditDepartmentModel, DepartmentResultOk, MessageResultOk, TicketDetailsModel, NewTicketModel, NewTicketResultOk, TicketWithMessagesDetailsModel, TicketListModelForAdmin } from './interfaces';


@Injectable({
    providedIn: 'root'
})
export class SupportService {
    headers: HttpHeaders;
    constructor(private http: HttpClient, private oidcSecurityService: OidcSecurityService) { }

    private setHeaders() {
        this.headers = new HttpHeaders();
        this.headers = this.headers.set('Content-Type', 'application/json');
        this.headers = this.headers.set('Accept', 'application/json');

        const token = this.oidcSecurityService.getToken();
        if (token !== '') {
            const tokenValue = 'Bearer ' + token;
            this.headers = this.headers.append('Authorization', tokenValue);
        }
    }
  getAllDepartmentsForAdmin(): Observable<DepartmentListModel[]> {
      this.setHeaders();
      return this.http
          .get(environment.apiBaseUrl + '/api/support/GetAllDepartmentsForAdmin', { headers: this.headers }) as Observable<DepartmentListModel[]>;
  }

  getAllTicketsForAdmin(): Observable<TicketListModel[]> {
      this.setHeaders();
      return this.http
          .get(environment.apiBaseUrl + '/api/support/GetAllTicketsForAdmin', { headers: this.headers }) as Observable<TicketListModel[]>;
  }

  getAllTicketMessagesForAdmin(): Observable<TicketMessageListForAdminModel[]> {
      this.setHeaders();
      return this.http
          .get(environment.apiBaseUrl + '/api/support/GetAllTicketMessagesForAdmin', { headers: this.headers }) as Observable<TicketMessageListForAdminModel[]>;
  }

  getActiveTicketDepartments(): Observable<DepartmentListModel[]> {
      this.setHeaders();
      return this.http
          .get(environment.apiBaseUrl + '/api/support/GetActiveDepartments', { headers: this.headers }) as Observable<DepartmentListModel[]>;
  }

  addDepartment(model: AddEditDepartmentModel): Observable<DepartmentResultOk> {
      this.setHeaders();
  return this.http
      .post(environment.apiBaseUrl + '/api/support/AddDepartment', model, { headers: this.headers }) as Observable<DepartmentResultOk>
}
getDepartmentByIdForAdmin(id: number): Observable<AddEditDepartmentModel> {
    this.setHeaders();
  return this.http
      .get(environment.apiBaseUrl + '/api/support/GetDepartmentByIdForAdmin?id=' + id, { headers: this.headers }) as Observable<AddEditDepartmentModel>
}

updateDepartment(model: AddEditDepartmentModel): Observable<DepartmentResultOk> {
    this.setHeaders();
    return this.http
        .post(environment.apiBaseUrl + '/api/support/UpdateDepartment', model, { headers: this.headers }) as Observable<DepartmentResultOk>;
    }

    closeTicket(model: any): Observable<MessageResultOk> {
        this.setHeaders();
        return this.http
            .post(environment.apiBaseUrl + '/api/support/CloseTicket', model, { headers: this.headers }) as Observable<MessageResultOk>;
    }
    
getUserTickets(): Observable<TicketListModel[]> {
    this.setHeaders();
    return this.http
        .get(environment.apiBaseUrl + '/api/support/GetUserTickets', { headers: this.headers }) as Observable<TicketListModel[]>;
    }
    getMyTicketBaseInfoById(id: string): Observable<TicketDetailsModel> {
        this.setHeaders();
        return this.http
            .get(environment.apiBaseUrl + '/api/support/GetUserTicketById/' + id, { headers: this.headers }) as Observable<TicketDetailsModel>;
    }

    
    addUserTicket(model: NewTicketModel): Observable<NewTicketResultOk> {
    this.setHeaders();
    return this.http
        .post(environment.apiBaseUrl + '/api/support/AddUserTicket', model, { headers: this.headers }) as Observable<NewTicketResultOk>;
    }
    getUserUnreadTicketsCount(): Observable<any> {
        this.setHeaders();
        return this.http
            .get(environment.apiBaseUrl + '/api/support/GetUserUnreadTicketsCount', { headers: this.headers }) as Observable<any>;
    }
    

addReply(model:  any): Observable<any> {
    this.setHeaders();
    return this.http
        .post(environment.apiBaseUrl + '/api/support/AddTicketMessage', model, { headers: this.headers }) as Observable<any>;
}
addReplyByAdmin(model:  any): Observable<any> {
    this.setHeaders();
    return this.http
        .post(environment.apiBaseUrl + '/api/support/AddTicketRelyMessageByAdmin', model, { headers: this.headers }) as Observable<any>;
}

getMyTicketInfoById(id: string): Observable<any> {
    this.setHeaders();
    return this.http
        .get(environment.apiBaseUrl + '/api/support/GetMyTicketInfoById/' + id, { headers: this.headers }) as Observable<any>;
}

getTicketDetailsByIdForAdmin(id: string): Observable<TicketWithMessagesDetailsModel> {
    this.setHeaders();
    return this.http
        .get(environment.apiBaseUrl + '/api/support/GetTicketDetailsByIdForAdmin?id=' + id, { headers: this.headers }) as Observable<TicketWithMessagesDetailsModel>;
}
getOtherTicketsOfCustomerExceptId(exceptId: string, customerId: number): Observable<TicketListModelForAdmin[]> {
    this.setHeaders();
    return this.http
        .get(environment.apiBaseUrl + '/api/support/GetOtherTicketsOfCustomerExceptId?exceptId=' + exceptId + '&customerId=' + customerId,
            { headers: this.headers }) as Observable<TicketListModelForAdmin[]>;
}


}
