import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { TradingAccountService } from '../services/trading-account.service';
import * as signalR from '@aspnet/signalr';
import { environment } from '../../environments/environment';
import { UserService } from '../services/user.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { RoAccountService } from '../services/ro-account.service';



@Component({
  selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
    defAccount: any;
    frontBaseUrl = environment.frontBaseUrl;
    deposits: any[] = [];
    withdraws: any[] = [];
    credits: any[] = [];
    tradeHistories: any[] = [];
    totalTradesProgitLoss = 0;
    firstStepMsg = '';
    frmWithdraw: FormGroup;
    frmRoTransfer: FormGroup;
    securityTokenSent = false;
    roAccount: any;
    roTransactions: any[] = [];
  
    constructor(private toastr: ToastrService, private router: Router, private modalService: NgbModal, private roAccountService: RoAccountService,
        private tradingAccountService: TradingAccountService, public userService: UserService, private fb: FormBuilder) {}

    ngOnInit() {
        //this.userService.getUserStatus();
        const connectionApiAdmin = new signalR.HubConnectionBuilder()
            .configureLogging(signalR.LogLevel.Information)
            .withUrl(environment.adminBaseUrl + "/notifications")
            .build();
        connectionApiAdmin.start().then(function () {
            console.log('Connected hub admin');
        }).catch(function (err) {
            return console.error(err.toString());
        });

        connectionApiAdmin.on("prvcmd", (msg) => {
            const jsonMsg = JSON.parse(msg);

            switch (jsonMsg.command) {

                case "reloadDefAccount":
                    if (this.userService.userStatus && this.userService.userStatus.id === jsonMsg.uId) {

                        //   console.log('prvcmd resid reloadUserIranWalletBalance');
                        setTimeout(() => {
                            this.getDefAccount();
                        }, 1000);


                    }
                    break;
                case "reloadTransactionsOfDefAccount":
                    if (this.userService.userStatus && this.userService.userStatus.id === jsonMsg.uId) {

                        //   console.log('prvcmd resid reloadUserIranWalletBalance');
                        setTimeout(() => {
                            this.getDeposits();
                            this.getWithdraws();
                            this.getCredits();
                            this.getTradeHistory();
                        }, 1000);


                    }
                    break;
                case "reloadRoAccount":
                    if (this.userService.userStatus && this.userService.userStatus.id === jsonMsg.uId) {

                        setTimeout(() => {
                            this.getRoAccount();
                        }, 1000);
                    }
                    break;
                case "reloadTransactionsOfRoAccount":
                    if (this.userService.userStatus && this.userService.userStatus.id === jsonMsg.uId) {

                        setTimeout(() => {
                            ///  this.getRoTransactions();
                            this.getRoAccount();

                        }, 1000);


                    }
                    break;
               
                default:
                
                    break;
            }
        });


        const connectionApi = new signalR.HubConnectionBuilder()
            .configureLogging(signalR.LogLevel.Information)
            .withUrl(environment.apiBaseUrl + "/notifications")
            .build();
        connectionApi.start().then(function () {
            console.log('Connected hub api');
        }).catch(function (err) {
            return console.error(err.toString());
        });

        connectionApi.on("prvcmd", (msg) => {
            const jsonMsg = JSON.parse(msg);

            switch (jsonMsg.command) {

                case "reloadRoAccount":
                    if (this.userService.userStatus && this.userService.userStatus.id === jsonMsg.uId) {

                        setTimeout(() => {
                            this.getRoAccount();
                        }, 1000);
                    }
                    break;
                case "reloadTransactionsOfRoAccount":
                    if (this.userService.userStatus && this.userService.userStatus.id === jsonMsg.uId) {

                        setTimeout(() => {
                            ///  this.getRoTransactions();
                            this.getRoAccount();

                        }, 1000);


                    }

                default:
                    break;
            }
        });

        this.getDefAccount();
        this.getRoAccount();
       
    }
 
    getRoAccount() {
        this.roAccountService.getUserRoAccount()
            .subscribe(res => {
                //    console.log('getRoAccount', res);
                this.roAccount = res;
                this.getRoTransactions();
            }, error => {
                this.roAccount = null;
                console.log(error);
            });
    }
    getRoTransactions() {
        this.roAccountService.getRoTransactions(this.roAccount.id)
            .subscribe(res => {
                //   console.log('getRoTransactions', res);
                this.roTransactions = res;
            }, error => {
                this.roTransactions = [];
                console.log(error);
            });
    }
    getDefAccount() {
        this.tradingAccountService.getUserDefaultAccount()
            .subscribe(res => {
               //console.log('getDefAccount',res);
                this.defAccount = res;
                this.getDeposits();
                this.getCredits();
                this.getWithdraws();
                this.getTradeHistory();
            }, error => {

                    console.log(error);
            });
    }
    getDeposits() {
        if (this.defAccount) {
            this.tradingAccountService.getDepositsOfUserAccount(this.defAccount.id)
                .subscribe(res => {
                  //  console.log('getDeposits', res);
                    this.deposits = res;
                }, error => {
                    this.deposits = [];
                    console.log(error);
                });
        }
        else {
            this.deposits = [];
        }
    }
    getWithdraws() {
        if (this.defAccount) {
        this.tradingAccountService.getWithdrawsOfUserAccount(this.defAccount.id)
            .subscribe(res => {
              //  console.log('getWithdraws', res);
                this.withdraws = res;
            }, error => {
                    this.withdraws = [];
                console.log(error);
            });
        }
        else {
            this.withdraws = [];
        }
    }
    getCredits() {
        if (this.defAccount) {
            this.tradingAccountService.getCreditsOfUserAccount(this.defAccount.id)
                .subscribe(res => {
                //    console.log('getCredits', res);
                    this.credits = res;
                }, error => {
                        this.credits = [];
                    console.log(error);
                });
        }
        else {
            this.credits = [];
        }
    }
    getTradeHistory() {
        if (this.defAccount) {
            this.tradingAccountService.getTradeHistoryOfUserAccount(this.defAccount.id)
                .subscribe(res => {
                       // console.log('getTradeHistory', res);
                    this.tradeHistories = res;
                  this.calculateSumPL();
                }, error => {
                        this.tradeHistories = [];
                    console.log(error);
                });
        }
        else {
            this.tradeHistories = [];
        }
    }
    calculateSumPL() {
        this.totalTradesProgitLoss = 0;
        this.tradeHistories.forEach((value) =>{
            this.totalTradesProgitLoss = this.totalTradesProgitLoss+ parseFloat(value.profitLoss);
        }); 
        
       
    }

    openModal(content) {
        this.securityTokenSent = false;
        this.firstStepMsg = '';
        this.frmWithdraw = this.fb.group({
            amount: ['', Validators.required],
            securityToken: [''],
        });
        this.modalService.open(content);
    }

    openModalMt5(content) {
        this.securityTokenSent = false;
        this.firstStepMsg = '';
        this.frmRoTransfer = this.fb.group({
            trasnferType: ['3', Validators.required],
            amount: ['', Validators.required],
            description: [''],
            securityToken: [''],

        });
        this.modalService.open(content);
    }
    roTransferNextClick() {
        if (this.frmRoTransfer.invalid) {
            this.toastr.error('Please complete the form');
            return;
        }
        let model = {
            trasnferType: this.frmRoTransfer.value.trasnferType,
            amount: this.frmRoTransfer.value.amount,
            description: this.frmRoTransfer.value.description
        };

        this.roAccountService.submitRoTransferFirstStep(model).subscribe(res => {
            this.firstStepMsg = res.message;
            
            this.securityTokenSent = true;
            this.toastr.success(res.message);
        }, error => {
            // console.log(error);
            if (error.error.errors) {
                error.error.errors.forEach(d => {
                    this.toastr.error(d);
                })
            } else {
                this.toastr.error(error.error.error);
            }
        });
        return;
    }

    onSubmitRoTransfer() {
        if (this.frmRoTransfer.invalid) {
            this.toastr.error('Please complete the form');
            return;
        }
        if (!this.frmRoTransfer.value.securityToken) {
            this.toastr.error('Security token is required.');
            return;
        }

        let model = {
            trasnferType: this.frmRoTransfer.value.trasnferType,
           
            amount: this.frmRoTransfer.value.amount,
            description: this.frmRoTransfer.value.description,
            securityToken: this.frmRoTransfer.value.securityToken
        };

        this.roAccountService.addTransfer(model).subscribe(res => {
            this.modalService.dismissAll();
            this.toastr.success(res.message);
            this.securityTokenSent = false;
            this.firstStepMsg = '';
        }, error => {
            // console.log(error);
            if (error.error.errors) {
                error.error.errors.forEach(d => {
                    this.toastr.error(d);
                })
            } else {
                this.toastr.error(error.error.error);
            }
        });
    }
    mt5WithdrawNextClick() {
        if (this.frmWithdraw.invalid) {
            this.toastr.error('Please complete the form');
            return;
        }
        let model = {
            accountId: this.defAccount.id,
            accountNumber: this.defAccount.accountNumber,
            amount: this.frmWithdraw.value.amount
        };

        this.tradingAccountService.submitWithdrawRequestFirstStep(model).subscribe(res => {
            this.firstStepMsg = res.message;
            
            this.securityTokenSent = true;
            this.toastr.success(res.message);
        }, error => {
            // console.log(error);
            if (error.error.errors) {
                error.error.errors.forEach(d => {
                    this.toastr.error(d);
                })
            } else {
                this.toastr.error(error.error.error);
            }
        });
        return;
    }

    onSubmitWithdrawRequest() {
        if (this.frmWithdraw.invalid) {
            this.toastr.error('Please complete the form');
            return;
        }
        if (!this.frmWithdraw.value.securityToken) {
            this.toastr.error('Security token is required.');
            return;
        }

        let model = {
            accountId: this.defAccount.id,
            accountNumber: this.defAccount.accountNumber,
            amount: this.frmWithdraw.value.amount,
            securityToken: this.frmWithdraw.value.securityToken
        };

        this.tradingAccountService.addWithdrawRequest(model).subscribe(res => {
            this.modalService.dismissAll();
            this.toastr.success(res.message);
            this.securityTokenSent = false;
            this.firstStepMsg = '';
            this.getWithdraws();
        }, error => {
            // console.log(error);
            if (error.error.errors) {
                error.error.errors.forEach(d => {
                    this.toastr.error(d);
                })
            } else {
                this.toastr.error(error.error.error);
            }
        });
    }

    
}
