import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { interval, Subscription } from 'rxjs';

import { User } from './interfaces';
import { environment } from '../../environments/environment';
import { OidcSecurityService } from 'angular-auth-oidc-client';

@Injectable({ providedIn: 'root' })
export class ParticipationService implements OnDestroy {
    headers: HttpHeaders;
    constructor(private http: HttpClient, private oidcSecurityService: OidcSecurityService) { }

    private setHeaders() {
        this.headers = new HttpHeaders();
        this.headers = this.headers.set('Content-Type', 'application/json');
        this.headers = this.headers.set('Accept', 'application/json');

        const token = this.oidcSecurityService.getToken();
        if (token !== '') {
            const tokenValue = 'Bearer ' + token;
            this.headers = this.headers.append('Authorization', tokenValue);
        }
    }
    getUserParticipationStatus(): any {
        this.setHeaders();
        return this.http.get<any>(`${environment.apiBaseUrl}/api/Participate/GetUserParticipateStatus`, { headers: this.headers });

    }
    addNewInvestRequest(model: any): any {
        this.setHeaders();
        return this.http.post<any>(`${environment.apiBaseUrl}/api/Participate/AddNewInvestRequest`, model, { headers: this.headers });
    }
    addNewTraderRequest(model: any): any {
        this.setHeaders();
        return this.http.post<any>(`${environment.apiBaseUrl}/api/Participate/AddNewTraderRequest`, model, { headers: this.headers });
    }
    getInvestorParticipatesOfTrader(): any {
        this.setHeaders();
        return this.http.get<any>(`${environment.apiBaseUrl}/api/Participate/GetInvestorParticipatesOfTrader`, { headers: this.headers });

    }
    getInvestorParticipatesOfUser(): any {
        this.setHeaders();
        return this.http.get<any>(`${environment.apiBaseUrl}/api/Participate/GetInvestorParticipatesOfUser`, { headers: this.headers });

    }
    getInvestorParticipatesOfUserOfTrader(traderId: string): any {
        this.setHeaders();
        return this.http.get<any>(`${environment.apiBaseUrl}/api/Participate/GetInvestorParticipatesOfUserOfTrader?traderId=` + traderId, { headers: this.headers });

    }
    
    
    getUserTraderProfile(): any {
        this.setHeaders();
        return this.http.get<any>(`${environment.apiBaseUrl}/api/Participate/GetUserTraderProfile`, { headers: this.headers });

    }
    getTraderProfile(id:string): any {
        this.setHeaders();
        return this.http.get<any>(`${environment.apiBaseUrl}/api/Participate/GetTraderProfile/${id}`, { headers: this.headers });
    }
    
    cancelTraderRequest(): any {
        this.setHeaders();
        return this.http.get<any>(`${environment.apiBaseUrl}/api/Participate/CancelTraderRequest`, { headers: this.headers });

    }
    getApprovedTraders(): any {
        this.setHeaders();
        return this.http.get<any>(`${environment.apiBaseUrl}/api/Participate/GetApprovedTraders`, { headers: this.headers });

    }

    cancelInvestRequest(id: string): any {
        this.setHeaders();
        return this.http.get<any>(`${environment.apiBaseUrl}/api/Participate/CancelInvestRequest?id=`+id, { headers: this.headers });

    }
    getTraderTradeHistory(id: string): any {
        this.setHeaders();
        return this.http.get<any[]>(`${environment.apiBaseUrl}/api/Participate/GetTraderTradeHistory/${id}`, { headers: this.headers });
    }
    

    ngOnDestroy() {
        
    }

}