import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/user.service';
import { environment } from '../../../environments/environment';
import { SupportService } from '../../services/support.service';
import * as signalR from '@aspnet/signalr';


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

    public productsCollapsed = false;
    public coursesCollapsed = false;
    frontBaseUrl = environment.frontBaseUrl;
    newMessagesCount = 0;

    constructor(public userService: UserService, private supportService: SupportService) { }

    ngOnInit() {
        const connectionApiAdmin = new signalR.HubConnectionBuilder()
            .configureLogging(signalR.LogLevel.Information)
            .withUrl(environment.adminBaseUrl + "/notifications")
            .build();
        connectionApiAdmin.start().then(function () {
            console.log('Connected hub admin');
        }).catch(function (err) {
            return console.error(err.toString());
        });

        connectionApiAdmin.on("prvcmd", (msg) => {
            const jsonMsg = JSON.parse(msg);

            switch (jsonMsg.command) {
                case "reloadUserSupportTickets":
                    if (this.userService.userStatus && this.userService.userStatus.id === jsonMsg.uId) {

                        setTimeout(() => {
                            this.getUnreadSupportMessagesCount();
                        }, 1000);
                    }
                default:
                    break;
            }
        });
        const connectionApiApi = new signalR.HubConnectionBuilder()
            .configureLogging(signalR.LogLevel.Information)
            .withUrl(environment.apiBaseUrl + "/notifications")
            .build();
        connectionApiApi.start().then(function () {
            console.log('Connected hub api');
        }).catch(function (err) {
            return console.error(err.toString());
        });

        connectionApiApi.on("prvcmd", (msg) => {
            const jsonMsgApi = JSON.parse(msg);

            switch (jsonMsgApi.command) {
                case "reloadUserSupportTickets":
                    if (this.userService.userStatus && this.userService.userStatus.id === jsonMsgApi.uId) {

                        setTimeout(() => {
                            this.getUnreadSupportMessagesCount();
                        }, 1000);
                    }
                default:
                    break;
            }
        });
        this.getUnreadSupportMessagesCount();
        setInterval(() => {
            const body = document.querySelector('body');

            // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
            let menuItems = document.querySelectorAll('.sidebar .nav-item');
           // console.log(menuItems);
            document.querySelectorAll('.sidebar .nav-item').forEach(function (el) {
                el.addEventListener('mouseover', function () {
                    if (body.classList.contains('sidebar-icon-only')) {
                        el.classList.add('hover-open');
                    }
                });
                el.addEventListener('mouseout', function () {
                    if (body.classList.contains('sidebar-icon-only')) {
                        el.classList.remove('hover-open');
                    }
                });
            });
        }, 2000);
        
    }

    toggleCollapsed(name) {
        this.closeAllCollapsedMenus();
        switch (name) {
            case 'courses':
                this.coursesCollapsed = true;
                break;
            case 'products':
                this.productsCollapsed = true;
                break;
            default:
                break;
        }
    }
    getUnreadSupportMessagesCount() {
        this.supportService.getUserUnreadTicketsCount()
            .subscribe(data => {
                this.newMessagesCount = data.unreadTicketsCount;

            },
                error => {

                    console.log('error in getUnreadSupportMessagesCount', error);
                    this.newMessagesCount = 0;
                });
    }
  


    closeAllCollapsedMenus() {
          this.productsCollapsed = false;
        this.coursesCollapsed = false;
    }

}
