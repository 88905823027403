import { Component, OnInit } from '@angular/core';
import { OptionsInput } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';

@Component({
  selector: 'app-economic-calendar',
    templateUrl: './economic-calendar.component.html',
    styleUrls: ['./economic-calendar.component.scss']
})
export class EconomicCalendarComponent implements OnInit {
 
   
  constructor() { }

  ngOnInit() {
  
  }

 

}
