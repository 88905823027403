import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-course-forex-options',
    templateUrl: './course-forex-options.component.html',
    styleUrls: ['./course-forex-options.component.scss']
})
export class CourseForexOptionsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
