import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { Page404Component } from './error-pages/page404/page404.component';
import { Page500Component } from './error-pages/page500/page500.component';
import { ProductsIndexComponent } from './general-pages/products-index/products-index.component';
import { ProductCryptoComponent } from './general-pages/product-crypto/product-crypto.component';
import { ProductStockComponent } from './general-pages/product-stock/product-stock.component';
import { ProductForexComponent } from './general-pages/product-forex/product-forex.component';
import { ProductIndicesComponent } from './general-pages/product-indices/product-indices.component';
import { ProductCommodityComponent } from './general-pages/product-commodity/product-commodity.component';
import { CoursesIndexComponent } from './general-pages/courses-index/courses-index.component';
import { CourseBondsComponent } from './general-pages/course-bonds/course-bonds.component';
import { CourseForexOptionsComponent } from './general-pages/course-forex-options/course-forex-options.component';
import { CourseForexComponent } from './general-pages/course-forex/course-forex.component';
import { CourseEquitiesComponent } from './general-pages/course-equities/course-equities.component';
import { CourseCfdsComponent } from './general-pages/course-cfds/course-cfds.component';
import { CourseFuturesComponent } from './general-pages/course-futures/course-futures.component';
import { CourseOptionsComponent } from './general-pages/course-options/course-options.component';
import { PlatformsComponent } from './general-pages/platforms/platforms.component';
import { PartnersComponent } from './general-pages/partners/partners.component';
import { AboutUsComponent } from './general-pages/about-us/about-us.component';
import { CourseDetailsComponent } from './general-pages/course-details/course-details.component';
import { AccountWizardComponent } from './account-wizard/account-wizard.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { AuthorizationGuard } from './services/auth.gaurd';
import { WebtradeIndexComponent } from './webtrade/webtrade-index/webtrade-index.component';
import { RoAccountComponent } from './ro-account/ro-account.component';
import { EconomicCalendarComponent } from './economic-calendar/economic-calendar.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { SupportIndexComponent } from './support/support-index/support-index.component';

import { NewTicketComponent } from './support/new-ticket/new-ticket.component';
import { WithdrawListComponent } from './withdraw-list/withdraw-list.component';
import { ParticipationComponent } from './participation/index/participation.component';
import { ParticipationStartInvestorComponent } from './participation/start-investor/participation-start-investor.component';
import { ParticipationStartTraderComponent } from './participation/start-trader/participation-start-trader.component';
import { TraderDetailsComponent } from './participation/trader-details/trader-details.component';
import { DepositListComponent } from './deposit-list/deposit-list.component';
import { ProfileComponent } from './profile/profile.component';
import { ProfileAvatarComponent } from './profile-avatar/profile-avatar.component';
import { ProfileChangePasswordComponent } from './profile-change-password/profile-change-password.component';
import { CreditsListComponent } from './credits-list/credits-list.component';
import { TellAFriendIndexComponent } from './tell-a-friend/tell-a-friend-index/tell-a-friend-index.component';
import { UnderUpdateComponent } from './under-update/under-update.component';
import { TradeHistoryListComponent } from './trade-history-list/trade-history-list.component';
import { TraderTradeHistoryComponent } from './participation/trader-trade-history/trader-trade-history.component';



const routes: Routes = [
    { path: '', redirectTo: '/dashboard', pathMatch: 'full', canActivate: [AuthorizationGuard]  },
    { path: 'unauthorized', component: UnauthorizedComponent },
    { path: 'under-update', component: UnderUpdateComponent, canActivate: [AuthorizationGuard] },
    { path: 'dashboard', component: DashboardComponent, canActivate: [AuthorizationGuard] },
    { path: 'withdraw-list/:id', component: WithdrawListComponent, canActivate: [AuthorizationGuard] },
    { path: 'deposit-list/:id', component: DepositListComponent, canActivate: [AuthorizationGuard] },
    { path: 'credit-list/:id', component: CreditsListComponent, canActivate: [AuthorizationGuard] },
    { path: 'trade-history-list/:id', component: TradeHistoryListComponent, canActivate: [AuthorizationGuard] },
    
    { path: 'ro-account', component: RoAccountComponent, canActivate: [AuthorizationGuard] },
    { path: 'webtrade', component: WebtradeIndexComponent, canActivate: [AuthorizationGuard] },
    { path: 'account-wizard', component: AccountWizardComponent, canActivate: [AuthorizationGuard]  },
  { path: 'error-500', component: Page500Component },
  { path: 'error-404', component: Page404Component },
    { path: 'profile', component: ProfileComponent, canActivate: [AuthorizationGuard] },
    //{ path: 'profile/avatar', component: ProfileAvatarComponent, canActivate: [AuthorizationGuard] },
    { path: 'profile/change-password', component: ProfileChangePasswordComponent, canActivate: [AuthorizationGuard] },
    { path: 'courses', component: CoursesIndexComponent, canActivate: [AuthorizationGuard] },
    { path: 'course/bonds', component: CourseBondsComponent, canActivate: [AuthorizationGuard]  },
    { path: 'course/cfds', component: CourseCfdsComponent, canActivate: [AuthorizationGuard]},
    { path: 'course/equities', component: CourseEquitiesComponent, canActivate: [AuthorizationGuard] },
    { path: 'course/forex', component: CourseForexComponent, canActivate: [AuthorizationGuard]  },
    { path: 'course/forex-options', component: CourseForexOptionsComponent, canActivate: [AuthorizationGuard] },
    { path: 'course/futures', component: CourseFuturesComponent, canActivate: [AuthorizationGuard]  },
    { path: 'course/options', component: CourseOptionsComponent, canActivate: [AuthorizationGuard] },
    { path: 'course/details/:id/:slug', component: CourseDetailsComponent, canActivate: [AuthorizationGuard] },
    { path: 'platforms', component: PlatformsComponent, canActivate: [AuthorizationGuard] },
    { path: 'products', component: ProductsIndexComponent, canActivate: [AuthorizationGuard] },
    { path: 'product/crypto', component: ProductCryptoComponent, canActivate: [AuthorizationGuard] },
    { path: 'product/stock', component: ProductStockComponent, canActivate: [AuthorizationGuard] },
    { path: 'product/forex', component: ProductForexComponent, canActivate: [AuthorizationGuard]},
    { path: 'product/indices', component: ProductIndicesComponent, canActivate: [AuthorizationGuard]},
    { path: 'product/commodity', component: ProductCommodityComponent, canActivate: [AuthorizationGuard] },
    { path: 'economic-calendar', component: EconomicCalendarComponent, canActivate: [AuthorizationGuard] },
    { path: 'notifications', component: NotificationsComponent, canActivate: [AuthorizationGuard] },
    { path: 'support', component: SupportIndexComponent, canActivate: [AuthorizationGuard] },
    { path: 'support/new-ticket', component: NewTicketComponent, canActivate: [AuthorizationGuard]},
    { path: 'participation', component: ParticipationComponent, canActivate: [AuthorizationGuard] },
    { path: 'participation/start-investor', component: ParticipationStartInvestorComponent, canActivate: [AuthorizationGuard] },
    { path: 'participation/start-trader', component: ParticipationStartTraderComponent, canActivate: [AuthorizationGuard] },
    { path: 'participation/trader/:id', component: TraderDetailsComponent, canActivate: [AuthorizationGuard] },
    { path: 'participation/trader/:id/history', component: TraderTradeHistoryComponent, canActivate: [AuthorizationGuard] },
    { path: 'ib', component: TellAFriendIndexComponent, canActivate: [AuthorizationGuard] },
    { path: 'tell-a-friend', component: TellAFriendIndexComponent, canActivate: [AuthorizationGuard] },
    { path: '**', redirectTo: '/error-404'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
