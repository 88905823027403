import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import * as signalR from '@aspnet/signalr';

import { ToastrService } from 'ngx-toastr';
import { UserService } from './services/user.service';
import { error } from 'protractor';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
    retryCount = 0;
    isAuthenticated: boolean;
    userData: any;
    showSidebar: boolean = true;
  showNavbar: boolean = true;
  showFooter: boolean = true;
  showSettings: boolean = true;
    userVerifyStatus: any = {};
    constructor(private router: Router, public oidcSecurityService: OidcSecurityService,
        private toastr: ToastrService , private usersService: UserService
    ) {
        if (this.oidcSecurityService.moduleSetup) {
            this.doCallbackLogicIfRequired();
        } else {
            this.oidcSecurityService.onModuleSetup.subscribe(() => {
                this.doCallbackLogicIfRequired();
            });
        }
    // Removing Sidebar, Navbar, Footer for Documentation and Auth pages
    router.events.forEach((event) => {   
        if (event instanceof NavigationStart) {
            if (event['url'] == '/error-404' || event['url'] == '/error-500' || event['url'] == '/unauthorized' ) {
                this.showSidebar = false;

          this.showNavbar = false;
          this.showFooter = false;
          this.showSettings = false;
          document.querySelector('.main-panel').classList.add('w-100');
          document.querySelector('.page-body-wrapper').classList.add('full-page-wrapper');
                
                if (event['url'] == '/error-404' || event['url'] == '/error-500' || event['url'] == '/unauthorized') {
            document.querySelector('.content-wrapper').classList.add('p-0');
          }
        } else {
          
          this.showNavbar = true;
          this.showFooter = true;
          this.showSettings = true;
          document.querySelector('.main-panel').classList.remove('w-100');
          document.querySelector('.page-body-wrapper').classList.remove('full-page-wrapper');
          document.querySelector('.content-wrapper').classList.remove('auth', 'auth-img-bg');
          document.querySelector('.content-wrapper').classList.remove('p-0');
        }
      }
    });
  }

    ngOnInit() {
        this.retryCount = 0;
        this.getUserVerifyStatus();
        const connectionApiAdmin = new signalR.HubConnectionBuilder()
            .configureLogging(signalR.LogLevel.Information)
            .withUrl(environment.adminBaseUrl + "/notifications")
            .build();
        connectionApiAdmin.start().then(function () {
            console.log('Connected hub admin');
        }).catch(function (err) {
            return console.error(err.toString());
        });

        connectionApiAdmin.on("prvnotif", (msg) => {
            const jsonMsg = JSON.parse(msg);
            if (this.usersService.userStatus && this.usersService.userStatus.id === jsonMsg.uId) {
                this.toastr.info(jsonMsg.message);
            }
           
        });

        connectionApiAdmin.on("prvcmd", (msg) => {
            const jsonMsg = JSON.parse(msg);

            switch (jsonMsg.command) {

                case "reloadAccountStatus":
                    if (this.usersService.userStatus && this.usersService.userStatus.id === jsonMsg.uId) {

                        setTimeout(() => {

                            this.getUserVerifyStatus();

                        }, 1000);


                    }

                    break;
                default:

                    break;
            }
        });


        this.oidcSecurityService.getIsAuthorized().subscribe(auth => {
            this.isAuthenticated = auth;
            console.log('isAuthenticated', this.isAuthenticated);
        });

        this.oidcSecurityService.getUserData().subscribe(userData => {
            this.userData = userData;
          //  console.log('userData', this.userData);
            if (userData === null)
                this.login();
            
        }, error => {
              //  console.log('this.oidcSecurityService.getUserData() error', error);
                this.login();
        });

      

    // Scroll to top after route change
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0);
    });
  }

    login() {
        this.oidcSecurityService.authorize();
    }

    logout() {
        this.oidcSecurityService.logoff();
    }
   
    private doCallbackLogicIfRequired() {
        // Will do a callback, if the url has a code and state parameter.
        this.oidcSecurityService.authorizedCallbackWithCode(window.location.toString());
    }

    getUserVerifyStatus() {
        this.usersService.getUserVerifyStatus()
            .subscribe(res => {
                console.log('getUserVerifyStatus', res);
                this.userVerifyStatus = res;
            }, error => {
                    this.userVerifyStatus = {};
                    if (this.retryCount < 10) {
                        setTimeout(() => {
                            this.getUserVerifyStatus();
                            this.retryCount = this.retryCount + 1;
                        }, 250);
                    }
                    console.log('getUserVerifyStatus error',error);
            });
    }
}
