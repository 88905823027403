import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-course-futures',
    templateUrl: './course-futures.component.html',
    styleUrls: ['./course-futures.component.scss']
})
export class CourseFuturesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
