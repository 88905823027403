import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { JalaliPipe } from './jalali.pipe';


const pipes = [
 
    JalaliPipe
];

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: pipes,
  exports: pipes
})
export class SharedPipesModule { }
