import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';

import * as signalR from '@aspnet/signalr';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserService } from '../../services/user.service';
import { ParticipationService } from '../../services/participation.service';
import { environment } from '../../../environments/environment';
import { RoAccountService } from '../../services/ro-account.service';
import { timer } from 'rxjs';

@Component({
  selector: 'app-trader-trade-history',
    templateUrl: './trader-trade-history.component.html',
    styleUrls: ['./trader-trade-history.component.scss'],
})
export class TraderTradeHistoryComponent implements OnInit {
    loading = false;
    roAccount: any;
    investorParticipatesOfUserOfTrader: any[] = [];
    id: string = null;
    traderProfile: any = {};
    tradingHistory: any[] = [];
    totalTradesProgitLoss = 0;
    
    interval;
    subscribeTimer: any;
    constructor(private toastr: ToastrService, private router: Router, private modalService: NgbModal,
        private route: ActivatedRoute, private roAccountService: RoAccountService, 
        private userService: UserService, private participateService: ParticipationService, private fb: FormBuilder) { }

    ngOnInit() {

        this.id = this.route.snapshot.paramMap.get("id");
        setTimeout(() => {
            this.getTraderProfile();
            this.getTraderTradeHistory();
        }, 2000);
       
        const connectionApiAdmin = new signalR.HubConnectionBuilder()
            .configureLogging(signalR.LogLevel.Information)
            .withUrl(environment.adminBaseUrl + "/notifications")
            .build();
        connectionApiAdmin.start().then(function () {
            console.log('Connected hub admin');
        }).catch(function (err) {
            return console.error(err.toString());
        });

        connectionApiAdmin.on("prvcmd", (msg) => {
            const jsonMsg = JSON.parse(msg);

            switch (jsonMsg.command) {

                case "reloadRoAccount":
                    if (this.userService.userStatus && this.userService.userStatus.id === jsonMsg.uId) {

                        setTimeout(() => {
                            this.getRoAccount();
                        }, 1000);
                    }
                    break;
                case "reloadTransactionsOfRoAccount":
                    if (this.userService.userStatus && this.userService.userStatus.id === jsonMsg.uId) {

                        setTimeout(() => {
                            ///  this.getRoTransactions();
                            this.getRoAccount();

                        }, 1000);


                    }
                case "reloadTraderProfile":
                    setTimeout(() => {
                        this.getTraderProfile();
                        this.getTraderTradeHistory();
                    }, 1000);

                default:
                    break;
            }
        });

        const connectionApi = new signalR.HubConnectionBuilder()
            .configureLogging(signalR.LogLevel.Information)
            .withUrl(environment.apiBaseUrl + "/notifications")
            .build();
        connectionApi.start().then(function () {
            console.log('Connected hub api');
        }).catch(function (err) {
            return console.error(err.toString());
        });

        connectionApi.on("prvcmd", (msg) => {
            const jsonMsg = JSON.parse(msg);

            switch (jsonMsg.command) {

                case "reloadRoAccount":
                    if (this.userService.userStatus && this.userService.userStatus.id === jsonMsg.uId) {

                        setTimeout(() => {
                            this.getRoAccount();
                        }, 1000);
                    }
                    break;
                case "reloadTransactionsOfRoAccount":
                    if (this.userService.userStatus && this.userService.userStatus.id === jsonMsg.uId) {

                        setTimeout(() => {
                            ///  this.getRoTransactions();
                            this.getRoAccount();

                        }, 1000);


                    }

                default:
                    break;
            }
        });
        this.getRoAccount();
        
    }
   
    getTraderTradeHistory() {
        this.participateService.getTraderTradeHistory(this.id).subscribe(res => {
          //  console.log('getTraderTradeHistory', res);
            this.tradingHistory = res;
            this.calculateSumPL();
              
        }, error => {
            
                this.tradingHistory = [];
        });
    }

    calculateSumPL() {
        this.totalTradesProgitLoss = 0;
        this.tradingHistory.forEach((value) => {
            this.totalTradesProgitLoss = this.totalTradesProgitLoss + parseFloat(value.profitLoss);
        });


    }

    getRoAccount() {
        this.roAccountService.getUserRoAccount()
            .subscribe(res => {
             //   console.log('getRoAccount', res);
                this.roAccount = res;

            }, error => {
                this.roAccount = null;
                console.log(error);
            });
    }
    displayCardNumber(str) {
        let ret = '';
        let remaining = str;

        while (remaining) {
            let v = remaining.slice(0, 4);
            remaining = remaining.slice(v.length);
            ret += ' ' + v;
        }

        return ret;
    }
    getTraderProfile() {
        this.participateService.getTraderProfile(this.id).subscribe(res => {
            this.traderProfile = res;
           
           // console.log('getTraderProfile', this.traderProfile);
        }, error => {
                //console.log('getTraderProfile error', error);
                this.traderProfile = {};
        });
    }
 
}
