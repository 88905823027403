import { Injectable } from '@angular/core';
import { Router, CanActivate, CanLoad, ActivatedRouteSnapshot, RouterStateSnapshot, Route } from '@angular/router';
import { Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators';

import { OidcSecurityService } from 'angular-auth-oidc-client';
import { UserService } from './user.service';


@Injectable()
export class AuthorizationGuard implements CanActivate, CanLoad {
    constructor(private router: Router, private oidcSecurityService: OidcSecurityService,
        private userService: UserService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.checkUser();
    }

    canLoad(state: Route): Observable<boolean> {
        return this.checkUser();
    }

    private checkUser(): Observable<boolean> {
        return this.oidcSecurityService.getIsAuthorized().pipe(
            map((isAuthorized: boolean) => {
                if (!isAuthorized) {
                   
                    this.router.navigate(['/unauthorized']);
                    return false;
                }
                this.userService.GetUnderUpdateInfo().subscribe(res => {
                    if (res.underUpdateModeActive === true) {
                        this.router.navigate(['/under-update']);
                        return false;
                    } else {
                      //  this.router.navigate(['/dashboard']);
                        return true;
                    }
                  
                }, error => {
                        this.router.navigate(['/unauthorized']);
                        return false;
                });
                return true;
            })
        );
    }
}