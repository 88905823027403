import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { interval, Subscription } from 'rxjs';

import { User } from './interfaces';
import { environment } from '../../environments/environment';
import { OidcSecurityService } from 'angular-auth-oidc-client';

@Injectable({ providedIn: 'root' })
export class TradingAccountService implements OnDestroy {
    headers: HttpHeaders;
    constructor(private http: HttpClient, private oidcSecurityService: OidcSecurityService) { }

    private setHeaders() {
        this.headers = new HttpHeaders();
        this.headers = this.headers.set('Content-Type', 'application/json');
        this.headers = this.headers.set('Accept', 'application/json');

        const token = this.oidcSecurityService.getToken();
        if (token !== '') {
            const tokenValue = 'Bearer ' + token;
            this.headers = this.headers.append('Authorization', tokenValue);
        }
    }
    getUserDefaultAccount(): any {
        this.setHeaders();
        return this.http.get<any>(`${environment.apiBaseUrl}/api/TradingAccount/GetUserDefaultAccount`, { headers: this.headers });

    }
    getTradingAccountOfUser(id: string): any {
        this.setHeaders();
        return this.http.get<any>(`${environment.apiBaseUrl}/api/TradingAccount/GetTradingAccountOfUser/`+id, { headers: this.headers });

    }
    getDepositsOfUserAccount(id:string): any {
        this.setHeaders();
        return this.http.get<any>(`${environment.apiBaseUrl}/api/TradingAccount/GetDepositsOfUserAccount/` + id, { headers: this.headers });
    }
    getWithdrawsOfUserAccount(id: string): any {
        this.setHeaders();
        return this.http.get<any>(`${environment.apiBaseUrl}/api/TradingAccount/GetWithdrawsOfUserAccount/` + id, { headers: this.headers });
    }

    
    getCreditsOfUserAccount(id: string): any {
        this.setHeaders();
        return this.http.get<any>(`${environment.apiBaseUrl}/api/TradingAccount/GetCreditsOfUserAccount/` + id, { headers: this.headers });
    }
    getTradeHistoryOfUserAccount(id: string): any {
        this.setHeaders();
        return this.http.get<any>(`${environment.apiBaseUrl}/api/TradingAccount/GetTradeHistoryOfUserAccount/` + id, { headers: this.headers });
    }
    
    submitWithdrawRequestFirstStep(formValue): Observable<any> {
        this.setHeaders();
        return this.http.post(environment.apiBaseUrl + '/api/TradingAccount/SubmitMT5WithdrawRequestFirstStep', formValue, { headers: this.headers }) as Observable<any>;
    }
    
    addWithdrawRequest(formValue): Observable<any> {
        this.setHeaders();
        return this.http.post(environment.apiBaseUrl + '/api/TradingAccount/AddMT5WithdrawRequest', formValue, { headers: this.headers }) as Observable<any>;
    }

    ngOnDestroy() {
        
    }

}