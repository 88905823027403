import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, OnDestroy } from '@angular/core';
import * as signalR from '@aspnet/signalr';

import { Router } from '@angular/router';

import { ToastrService } from 'ngx-toastr';

import { Language, LocaleService, TranslationService } from 'angular-l10n';
import { DepartmentListModel, TicketListModel } from '../../services/interfaces';
import { SupportService } from '../../services/support.service';
import { environment } from '../../../environments/environment';
import { UserService } from '../../services/user.service';

@Component({
    selector: 'app-support-new-ticket',
    templateUrl: './new-ticket.component.html',
    styleUrls: ['./new-ticket.component.scss'],
})
export class NewTicketComponent implements OnInit, OnDestroy {
    @Language() lang: string;
    public ticketDepartments: Array<DepartmentListModel> = [];
    public tickets: Array<TicketListModel>=[];
    departmentIcons = [
        { image: '../../../../assets/images/open-book-icon.png' },
        { image: '../../../../assets/images/assets-icon.png' },
        { image: '../../../../assets/images/settings-icon.png' },
    ]
    loading = true;

    public frmNewTicket: FormGroup;

    constructor(private toastr: ToastrService,
        private formBuilder: FormBuilder,
        
        public router: Router, public locale: LocaleService,
        public translation: TranslationService,
        private supportService: SupportService) {
        document.dir = this.translation.translate("dir");
        document.documentElement.setAttribute('lang', this.translation.translate("lang"));
    }

    ngOnInit() {
        const connectionApiAdmin = new signalR.HubConnectionBuilder()
            .configureLogging(signalR.LogLevel.Information)
            .withUrl(environment.adminBaseUrl + "/notifications")
            .build();
        connectionApiAdmin.start().then(function () {
            console.log('Connected hub admin');
        }).catch(function (err) {
            return console.error(err.toString());
        });

        connectionApiAdmin.on("prvcmd", (msg) => {
            const jsonMsg = JSON.parse(msg);

            switch (jsonMsg.command) {

                case "reloadTicketDepartments":

                    setTimeout(() => {
                        this.loadTicketDepartments();
                    }, 1000);
                    break;
                  
                default:
                    break;
            }
        });


        this.loadUserTickets();
        this.loadTicketDepartments();
        this.createTicketForm();
    }


    ngOnDestroy(): void {

    }
    loadUserTickets() {
        this.loading = true;
        this.supportService.getUserTickets()
            .subscribe(data => {
                this.loading = false;
                this.tickets = data;
                console.log(this.tickets);
                if (this.tickets === undefined || this.tickets.length === 0) {
                    this.router.navigateByUrl("/support/new-ticket");
                }
            },
                error => {
                    this.loading = false;
                    console.log('error in getUserTickets', error);

                });

    }

    loadTicketDepartments() {
        this.loading = true;
        this.supportService.getActiveTicketDepartments()
            .subscribe(data => {
                this.loading = false;
                this.ticketDepartments = data;
            },
                error => {
                    this.loading = false;
                    this.ticketDepartments = [];
                    console.log('error in loadTicketDepartments', error);

                });
    }

    onUploadedNewTicket(fileUrl: string) {
        this.frmNewTicket.patchValue({ files: fileUrl });
    }

    createTicketForm() {
        // this.loadTicketDepartments();
        // console.log('createNewTicketForm', this.newTicket);
        this.frmNewTicket = this.formBuilder.group({
            subject: ['', [Validators.required, Validators.maxLength(50)]],
            description: ['', [Validators.required, Validators.maxLength(500)]],
            departmentId: ['', [Validators.required]],
            fileUrl: ['']
        });

    }


    onSubmitTicket() {
        const controls = this.frmNewTicket.controls;
        this.loading = true;

        // stop here if form is invalid
        if (this.frmNewTicket.invalid) {
            this.loading = false;
            this.toastr.error('Please complete the form');
            return;
        }
        const model = {
            subject: controls.subject.value,
            description: controls.description.value,
            fileUrl: controls.fileUrl.value,
            departmentId: controls.departmentId.value,

        };
        console.log('model', model);
        this.supportService.addUserTicket(model).subscribe(res => {
            this.loading = false;
            this.toastr.success(res.message);
            this.router.navigateByUrl("/support");
        }, err => {
            this.loading = false;
            this.toastr.error(err.error);

        });
        this.loading = false;
    }
}
