import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';

import * as signalR from '@aspnet/signalr';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserService } from '../../services/user.service';
import { ParticipationService } from '../../services/participation.service';
import { environment } from '../../../environments/environment';
import { RoAccountService } from '../../services/ro-account.service';
import { timer } from 'rxjs';

@Component({
  selector: 'app-participation-trader-details',
    templateUrl: './trader-details.component.html',
    styleUrls: ['./trader-details.component.scss'],
})
export class TraderDetailsComponent implements OnInit {
    loading = false;
    roAccount: any;
    investorParticipatesOfUserOfTrader: any[] = [];
    id: string = null;
    traderProfile: any = {};
    frmNewInvestRequest: FormGroup;
    securityTokenSent = false;
    timeLeft: number = 120;
    interval;
    subscribeTimer: any;
    constructor(private toastr: ToastrService, private router: Router, private modalService: NgbModal,
        private route: ActivatedRoute, private roAccountService: RoAccountService, 
        private userService: UserService, private participateService: ParticipationService, private fb: FormBuilder) { }

    ngOnInit() {

        this.id = this.route.snapshot.paramMap.get("id");
        setTimeout(() => {
            this.getTraderProfile();
        }, 2000);
       
        const connectionApiAdmin = new signalR.HubConnectionBuilder()
            .configureLogging(signalR.LogLevel.Information)
            .withUrl(environment.adminBaseUrl + "/notifications")
            .build();
        connectionApiAdmin.start().then(function () {
            console.log('Connected hub admin');
        }).catch(function (err) {
            return console.error(err.toString());
        });

        connectionApiAdmin.on("prvcmd", (msg) => {
            const jsonMsg = JSON.parse(msg);

            switch (jsonMsg.command) {

                case "reloadRoAccount":
                    if (this.userService.userStatus && this.userService.userStatus.id === jsonMsg.uId) {

                        setTimeout(() => {
                            this.getRoAccount();
                        }, 1000);
                    }
                    break;
                case "reloadTransactionsOfRoAccount":
                    if (this.userService.userStatus && this.userService.userStatus.id === jsonMsg.uId) {

                        setTimeout(() => {
                            ///  this.getRoTransactions();
                            this.getRoAccount();

                        }, 1000);


                    }
                case "reloadTraderProfile":
                    setTimeout(() => {
                        this.getTraderProfile();
                    }, 1000);

                default:
                    break;
            }
        });

        const connectionApi = new signalR.HubConnectionBuilder()
            .configureLogging(signalR.LogLevel.Information)
            .withUrl(environment.apiBaseUrl + "/notifications")
            .build();
        connectionApi.start().then(function () {
            console.log('Connected hub api');
        }).catch(function (err) {
            return console.error(err.toString());
        });

        connectionApi.on("prvcmd", (msg) => {
            const jsonMsg = JSON.parse(msg);

            switch (jsonMsg.command) {

                case "reloadRoAccount":
                    if (this.userService.userStatus && this.userService.userStatus.id === jsonMsg.uId) {

                        setTimeout(() => {
                            this.getRoAccount();
                        }, 1000);
                    }
                    break;
                case "reloadTransactionsOfRoAccount":
                    if (this.userService.userStatus && this.userService.userStatus.id === jsonMsg.uId) {

                        setTimeout(() => {
                            ///  this.getRoTransactions();
                            this.getRoAccount();

                        }, 1000);


                    }

                default:
                    break;
            }
        });
        this.getRoAccount();
        this.createNewInvestRequestForm();
        this.getInvestorParticipatesOfUserForTrader();
    }
    checkHasPending(): boolean {
        if (this.investorParticipatesOfUserOfTrader.some(d => d.status === 1)) {
            return true;
        } else {
            return false;
        }
    }
    getInvestorParticipatesOfUserForTrader() {
        this.participateService.getInvestorParticipatesOfUserOfTrader(this.id).subscribe(res => {
            this.investorParticipatesOfUserOfTrader = res;

            //   console.log('getInvestorParticipatesOfUser', this.investorParticipatesOfUser);
        }, error => {
            console.log('getInvestorParticipatesOfUser error', error);
                this.investorParticipatesOfUserOfTrader = [];
        });
    }

    getSecurityToken() {
        this.loading = true;
        this.userService.getNewSecurityToken().subscribe(res => {
            this.loading = false;
            this.toastr.success(res.message);
            
            this.securityTokenSent = true;
            this.interval = setInterval(() => {
                if (this.timeLeft > 0) {
                    this.timeLeft--;
                } else {
                    this.securityTokenSent = false;
                }
            }, 1000);
        }, error => {
                this.loading = false;
             console.log(error);
            if (error.error.errors) {
                error.error.errors.forEach(d => {
                    this.toastr.error(d);
                })
            } else {
                this.toastr.error(error.error.error);
            }
        });
       
       
    }
    oberserableTimer() {
        const source = timer(1000, 2000);
        const abc = source.subscribe(val => {
            console.log(val, '-');
            this.subscribeTimer = this.timeLeft - val;
        });
    }
   
    pauseTimer() {
        clearInterval(this.interval);
    }

    getRoAccount() {
        this.roAccountService.getUserRoAccount()
            .subscribe(res => {
             //   console.log('getRoAccount', res);
                this.roAccount = res;

            }, error => {
                this.roAccount = null;
                console.log(error);
            });
    }
    displayCardNumber(str) {
        let ret = '';
        let remaining = str;

        while (remaining) {
            let v = remaining.slice(0, 4);
            remaining = remaining.slice(v.length);
            ret += ' ' + v;
        }

        return ret;
    }
    getTraderProfile() {
        this.participateService.getTraderProfile(this.id).subscribe(res => {
            this.traderProfile = res;
           
         //   console.log('getTraderProfile', this.traderProfile);
        }, error => {
                //console.log('getTraderProfile error', error);
                this.traderProfile = {};
        });
    }
 
    createNewInvestRequestForm() {
       
        this.frmNewInvestRequest = this.fb.group({
            amount: ['', Validators.required],
            monthsCount: [12, Validators.required],
            acceptRules: [false, Validators.required],
            securityToken: ['', Validators.required]
        });
    }

   
    onSubmitInvestRequest() {
        if (this.frmNewInvestRequest.invalid) {
            this.toastr.error('Please complete the form');
            return;
        }
        this.loading = true;
        let model = {
            traderId: this.id,
            amount: this.frmNewInvestRequest.value.amount,
            securityToken: this.frmNewInvestRequest.value.securityToken,
            
        };
     //   console.log(model);
        //  alert('ok');
        this.participateService.addNewInvestRequest(model).subscribe(res => {
            this.loading = false;
            this.toastr.success(res.message);
            this.getTraderProfile();
            this.getInvestorParticipatesOfUserForTrader();
          
        }, error => {
                this.loading = false;
                console.log('onSubmitTraderRequest', error.error);
                if (error.error) {
                  
                error.error.forEach(d => {
                    console.log(d);
                    this.toastr.error(d);
                })
            } else {
               this.toastr.error(error.error.error);
            }
        });
    }

    secondsToHms(d) {
        d = Number(d);
        var m = Math.floor(d % 3600 / 60);
        var s = Math.floor(d % 3600 % 60);

        var mDisplay = m > 0 ? m + (m == 1 ? ": " : " : ") : "00";
        var sDisplay = s > 0 ? s + (s == 1 ? "" : "") : "00";
        return mDisplay + sDisplay;
    }

    cancelInvestRequest(id: string) {
        if (!id) {
            alert('id is null');
            return;
        } else {
            this.loading = true;
            this.participateService.cancelInvestRequest(id).subscribe(res => {
                this.loading = false;
                this.toastr.success(res.message);
                this.getInvestorParticipatesOfUserForTrader();

            }, error => {
                    this.loading = false;
                console.log('cancelInvestRequest error', error);

            });
        }
    }
}
