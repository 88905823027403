import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

import * as signalR from '@aspnet/signalr';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserService } from '../../services/user.service';
import { ParticipationService } from '../../services/participation.service';


@Component({
  selector: 'app-participation',
    templateUrl: './participation.component.html',
    styleUrls: ['./participation.component.scss'],
})
export class ParticipationComponent implements OnInit {
    participationStatus: any[] = [];
    
    constructor(private toastr: ToastrService, private router: Router, private modalService: NgbModal,
        private participateService: ParticipationService, private fb: FormBuilder) { }

    ngOnInit() {
       this.getUserPaticipationStatus();

    }

    getUserPaticipationStatus() {
        this.participateService.getUserParticipationStatus().subscribe(res => {
            this.participationStatus = res;
           // console.log('getUserPaticipationStatus', this.participationStatus);
        }, error => {
                console.log('getUserPaticipationStatus error', error);
                this.participationStatus = [];
        });
    }
    
}
