import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { interval, Subscription } from 'rxjs';

import { User } from './interfaces';
import { environment } from '../../environments/environment';
import { OidcSecurityService } from 'angular-auth-oidc-client';

@Injectable({ providedIn: 'root' })
export class RoAccountService implements OnDestroy {
    headers: HttpHeaders;
    constructor(private http: HttpClient, private oidcSecurityService: OidcSecurityService) { }

    private setHeaders() {
        this.headers = new HttpHeaders();
        this.headers = this.headers.set('Content-Type', 'application/json');
        this.headers = this.headers.set('Accept', 'application/json');

        const token = this.oidcSecurityService.getToken();
        if (token !== '') {
            const tokenValue = 'Bearer ' + token;
            this.headers = this.headers.append('Authorization', tokenValue);
        }
    }

    getUserRoAccount(): any {
        this.setHeaders();
        return this.http.get<any>(`${environment.apiBaseUrl}/api/RoAccount/GetUserRoAccount`, { headers: this.headers });
    }

    getRoTransactions(id:string): any {
        this.setHeaders();
        return this.http.get<any>(`${environment.apiBaseUrl}/api/RoAccount/GetRoTransactions/` + id, { headers: this.headers });
    }
   
    submitRoTransferFirstStep(formValue): Observable<any> {
        this.setHeaders();
        return this.http.post(environment.apiBaseUrl + '/api/RoAccount/SubmitRoTransferFirstStep', formValue, { headers: this.headers }) as Observable<any>;
    }
    addTransfer(formValue): Observable<any> {
        this.setHeaders();
        return this.http.post(environment.apiBaseUrl + '/api/RoAccount/AddTransfer', formValue, { headers: this.headers }) as Observable<any>;
    }
    
   
    ngOnDestroy() {
        
    }

}