import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-faq2',
  templateUrl: './faq2.component.html',
  styleUrls: ['./faq2.component.scss']
})
export class Faq2Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
