import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

import * as signalR from '@aspnet/signalr';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserService } from '../../services/user.service';
import { ParticipationService } from '../../services/participation.service';
import { environment } from '../../../environments/environment';


@Component({
  selector: 'app-participation-start-trader',
    templateUrl: './participation-start-trader.component.html',
    styleUrls: ['./participation-start-trader.component.scss'],
})
export class ParticipationStartTraderComponent implements OnInit {
    loading = false;
    traderProfile: any = {};
    investorParticipatesOfTrader: any[] = [];
    frmNewTraderRequest: FormGroup;

    constructor(private toastr: ToastrService, private router: Router, private modalService: NgbModal,
        private userService: UserService, private participateService: ParticipationService, private fb: FormBuilder) { }

    ngOnInit() {

        const connectionApiAdmin = new signalR.HubConnectionBuilder()
            .configureLogging(signalR.LogLevel.Information)
            .withUrl(environment.adminBaseUrl + "/notifications")
            .build();
        connectionApiAdmin.start().then(function () {
            console.log('Connected hub admin');
        }).catch(function (err) {
            return console.error(err.toString());
        });

        connectionApiAdmin.on("prvcmd", (msg) => {
            const jsonMsg = JSON.parse(msg);

            switch (jsonMsg.command) {

                case "reloadTraderProfile":
                    if (this.userService.userStatus && this.userService.userStatus.id === jsonMsg.uId) {

                        setTimeout(() => {
                            this.getTraderProfile();
                            this.getInvestorParticipatesOfTrader();
                        }, 1000);


                    }
                    break;
               
                default:
                    break;
            }
        });

       this.getTraderProfile();
        this.getInvestorParticipatesOfTrader();
    }

    getTraderProfile() {
        this.participateService.getUserTraderProfile().subscribe(res => {
            this.traderProfile = res;
            if (!this.traderProfile || this.traderProfile.status!==3) {
                this.createNewTraderRequestForm();
            }
            console.log('getTraderProfile', this.traderProfile);
        }, error => {
                console.log('getTraderProfile error', error);
                this.traderProfile = {};
        });
    }
    getInvestorParticipatesOfTrader() {
        this.participateService.getInvestorParticipatesOfTrader().subscribe(res => {
            this.investorParticipatesOfTrader = res;
           
            console.log('getInvestorParticipatesOfTrader', this.investorParticipatesOfTrader);
        }, error => {
                console.log('getInvestorParticipatesOfTrader error', error);
                this.investorParticipatesOfTrader = [];
        });
    }

    createNewTraderRequestForm() {
        this.frmNewTraderRequest = this.fb.group({
            minAmountAccepted: ['', Validators.required],
            nickname: ['', Validators.required],
            maxSimulInvestorAccepted: ['', Validators.required],
            commissionPercentage: ['', Validators.required],
        });
    }

    cancelTraderRequest() {
        this.loading = true;
        this.participateService.cancelTraderRequest().subscribe(res => {
            this.loading = false;
            this.toastr.success(res.message);
            this.getTraderProfile();
            this.getInvestorParticipatesOfTrader();
        }, error => {
                this.loading = false;
            console.log('getInvestorParticipatesOfTrader error', error);
            
        });
    }

    onSubmitTraderRequest() {
        if (this.frmNewTraderRequest.invalid) {
            this.toastr.error('Please complete the form');
            return;
        }
        this.loading = true;
        let model = {
            nickname: this.frmNewTraderRequest.value.nickname,
            minMoneyAcceptToTrade: this.frmNewTraderRequest.value.minAmountAccepted,
            maxActiveInvestorSupported: this.frmNewTraderRequest.value.maxSimulInvestorAccepted,
            commissionPercentage: this.frmNewTraderRequest.value.commissionPercentage,
            
        };
     //   console.log(model);
        //  alert('ok');
        this.participateService.addNewTraderRequest(model).subscribe(res => {
            this.loading = false;
            this.toastr.success(res.message);
            this.getTraderProfile();
            this.getInvestorParticipatesOfTrader();
          
        }, error => {
                this.loading = false;
            if (error.error) {
                error.error.forEach(d => {
                    this.toastr.error(d.message);
                })
            } else {
               this.toastr.error(error.error.error);
            }
        });
    }
}
